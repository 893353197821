import React, {useState, useEffect} from 'react'
import SideBar from '../navbar/SideBar'
import Header from '../navbar/Header'
import IndividualMinerGraph from './IndividualMinerGraph'
import {useSelector, useDispatch} from 'react-redux'
import { axiosWithAuth } from '../../utils/axiosWithAuth'
import { useNavigate, useParams} from 'react-router-dom'
import Cookies from 'js-cookie'
export default function IndividualMinerView() {
    const history = useNavigate()
    const name = useParams()
    console.log('name', name)
    const user = useSelector(state => state.user.user)
    const [showSideBar, setShowSideBar] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const color = name.coin
    const graphCookie = JSON.parse(Cookies.get('historicBTCHashrate'))
    console.log('graphcookie', graphCookie)
    let coinCookie
    if(color === "litecoin"){
     coinCookie = JSON.parse(Cookies.get('ltcMinerView'))

    } else if(color === "bitcoin"){
        coinCookie = JSON.parse(Cookies.get('btcMinerView'))
    }
    const graphName = name.id + color


const goBack = () => {
    history('/minerdash')
}






  return (
    <div className="h-screen flex overflow-visible">
        <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
        <div className="relative flex flex-col flex-1  ">
        <Header text={""} userFirstName={user.firstName} userLastName={user.lastName}/>
        <div className="overflow-auto">
        <div className="flex flex-col min-h-screen overflow-auto items-center bg-dashboardBackGroundGray">
        <div className="flex flex-col w-11/12 md:w-6/7 sm:col-1/2 xl:col-1/2 xs:mt-8 md:mt-6 bg-white shadow-lg rounded-sm border border-slate-200 lg:mt-16">
       
        <div className="flex items-start ml-4 mt-2" onClick={() => goBack()}>
        <i className="text-2xl cursor-pointer fa-solid fa-arrow-left"></i>
        </div>
            <div className={`text-4xl flex my-8 border-${coinCookie.borderColor} border-b-2`}>
            {coinCookie.minerType}
            <br></br>
            {name.id}
            </div>
            <div className="h-72">
            <IndividualMinerGraph color={color} name={graphName} data={graphCookie}/>
            </div>
            <div className="w-full h-48 flex flex-col items-start justify-center">
                <div className="my-1">
                    <span className="text-2xl my-8 font-semibold">Coin:</span><a className="underline font-medium text-lg ml-2" href="https://en.wikipedia.org/wiki/Bitcoin" target="_blank">Bitcoin</a>
                </div >
                <div  className="my-1">
                    <span className="text-2xl my-8 font-semibold">Pool:</span><span className="font-medium text-lg ml-2">stratum+tcp://btc-na.f2pool.com:1314</span>
                </div>
                <div  className="my-1">
                    <span className="text-2xl my-8 font-semibold">Local IP:</span><span className="underline ml-2 text-lg font-medium">127.00.1.3</span>
                </div>
                <div  className="my-1">
                    <span className="text-2xl my-8 font-semibold">Algorithm:</span><a className="underline ml-2 text-lg font-medium" href="https://en.wikipedia.org/wiki/SHA-2" target="_blank">SHA-256</a>
                </div>
            </div>
        </div>
            </div>
            </div>
            </div>
    </div>
  )
}
