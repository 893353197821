import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useLocation, Link} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import styled from 'styled-components'
import './App.css';



import PrivateRoute from './utils/PrivateRoute';
import Login from './pages/login/Login'
import Landing from './pages/login/Landing';
import UserDash from './pages/dashboard/UserDash';
import MinerDash from './pages/dashboard/MinerDash'
import TemporaryMinerDash from './pages/dashboard/TemporaryMinerDash'
import IndividualMinerView from './partials/dashboard/IndividualMinerView';
import FundDash from './pages/dashboard/FundDash';
import Settings from './pages/Settings';
import SignUp from './pages/login/SignUp';
import PasswordResetWithEmail from './pages/login/PasswordResetWithEmail';
import CreditCardForm from './partials/forms/CreditCardForm';
import BillingInfoForm from './partials/forms/BillingInfoForm';
import AuthorizationForm from './partials/forms/AuthorizationForm';
import SubscriptionPaymentCompletedPage from './pages/payment/SubscriptionPaymentCompletedPage';
import SubscriptionPaymentPage from './pages/payment/SubscriptionPaymentPage'


const Main = styled.main``;

const AnimatedTransition = () => {
  const location = useLocation()
 
  const [transitionName, setTransitionName] = useState("next")
  return (
    <TransitionGroup component={Main}>
      <CSSTransition
      key={location.pathname}
      classNames={transitionName}
      timeout={3000}
      >
        <Routes location={location}>
        <Route path="" element={<Login/>} exact/>
        <Route path="login" element={<Login/>}/>
        <Route path="registration" element={<SignUp/>}/>
        <Route path="emailpasswordreset" element={<PasswordResetWithEmail/>}/>
        <Route path="minerdash" element={<TemporaryMinerDash/>}/>
        <Route path="tempminerdash" element={<TemporaryMinerDash/>}/>
        <Route path="minerdash/:coin/:id" element={<IndividualMinerView/>}/>
        <Route element={<PrivateRoute/>}>
        <Route path="userdash" element={<UserDash/>}/>
        <Route path="funddash" element={<FundDash/>}/>
        <Route path="settings" element={<Settings/>}/>
        <Route path="subscriptionpage/:query" element={<SubscriptionPaymentPage/>}/>
        <Route path="subscription/:id" element={<BillingInfoForm/>}/>
        <Route path="subscription/:id/confirm" element={<CreditCardForm/>}/>
        <Route path="subscription/:id/authorization" element={<AuthorizationForm/>}/>
        <Route path="subscription/completed" element={<SubscriptionPaymentCompletedPage/>}/>
        </Route>
      </Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}


function App() {


  return (
    <section className="App">
      <AnimatedTransition/>
    </section>
  );
}

export default App;
