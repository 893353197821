import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { cryptoActions } from "../../store/ducks/cryptoDucks";
import * as yup from "yup";
import Cookies from "js-cookie";
import { axiosWithAuth } from "../../utils/axiosWithAuth";
import axios from 'axios'
import CreditCardInput from 'react-credit-card-input';
import creditCardFormSchema from "../../utils/yupSchemas/creditCardFormSchema";
import ErrorModal from "../errorModals/ErrorModal";
import AuthorizationForm from "./AuthorizationForm";
const initialCreditCardState = {
  cardNumber: "",
  cardDate: "",
  ccv: "",
};
const initialErrorState = {
  cardNumber: "",
  cardDate: "",
  ccv: "",
};
export default function CreditCardForm(wyreID, accountInfo) {
  // const billingInfo = JSON.parse(Cookies.get("billinginfo"));
  const user = useSelector((state) => state.user.user);
  const bitcoinUSDValue = useSelector(state => state.crypto.btcUSDPrice)
  const ethereumUSDValue = useSelector(state => state.crypto.ethUSDPrice)
  const cakeUSDValue = useSelector(state => state.crypto.cakeUSDPrice)
  const polyUSDValue = useSelector(state => state.crypto.polyUSDPrice)
  const currentETHAmount = useSelector(state => state.crypto.ethAmount)
  const currentCAKEAmount = useSelector(state => state.crypto.cakeAmount)
  const currentBTCAmount = useSelector(state => state.crypto.btcAmount)
  const currentPOLYAmount = useSelector(state => state.crypto.polyAmount)

  const history = useNavigate();
  const billingInfo = Cookies.get('billinginfo')
  const { id } = useParams();
  const [totalFundUnits, setTotalFundUnits] = useState(null) 
  const [form, setForm] = useState(initialCreditCardState);
  const [page, setPage] = useState("creditcard")
  const [loadingText, setLoadingText] = useState('loading')
  const [reservation, setReservation] = useState(null)
  const [authorizationStatus, setAuthorizationStatus] = useState({})
  const [reservationQuote, setReservationQuote] = useState({})
  const [wyreOrderID, setWyreOrderID] = useState(null)
  const [ip, setIP] = useState(null)
  const [wyreOrderStatus, setWyreOrderStatus] = useState({})
  const [spreedlyToken, setSpreedlyToken] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(initialErrorState);
  const [errorState, setErrorState] = useState(true)
  const ethPriceAndAmount = (currentETHAmount * ethereumUSDValue)
  const cakePriceAndAmount = (currentCAKEAmount * cakeUSDValue)
  const btcPriceAndAmount = (currentBTCAmount * bitcoinUSDValue)
  const polyPriceAndAmount = (currentPOLYAmount * polyUSDValue)
  const cryptoFundWorth = ethPriceAndAmount  + cakePriceAndAmount + btcPriceAndAmount + polyPriceAndAmount
  const unitValue = cryptoFundWorth / totalFundUnits
  console.log('reservation', reservation)



  let reservationConfig = {
    wyreID:wyreID,
    amount:id
  }
  
  const startWyreReservation = (id) => {
    let config = {
      method:"POST",
      url:'http://localhost:3500/auth/wyreCreation',
      data:id
    }
    axiosWithAuth().post('/auth/createReservation', id).then(response => {
      setTimeout(() => {
        let test = response.data.reservation
        console.log('actual reservation', test)
        setReservation(test)
        getWyreReservationInformation(test)
      })
    }, 800)
    .catch(error => {
      console.log(error)
    })
  }
  const getUserIP = () => {
    axios.get('https://geolocation-db.com/json/')
    .then(res => {
      setIP(res.data.IPv4)
    })
    .catch(err => {
      console.log(err)
    })
  }
 
  const getWyreReservationInformation = (id) => {
    let jsonID = JSON.stringify(id)
    console.log('jsonID for reservationQuote', jsonID)
    axiosWithAuth().post('/auth/reservationinfo', {jsonID})
    .then(response => {
      console.log('response for reservationQuote', response.data)
      setReservationQuote(response.data)
    })
    .catch(error => {
      console.log(error)
    })
  }



  useEffect(() => {
    //step 1: creating reservation
    startWyreReservation(reservationConfig)
    //getting userIP for later account verification
    getUserIP()
  }, [])

 

  useEffect( () => {
    return axiosWithAuth()
    .get('/fund/currentfundworth')
    .then((res) => {
      setTotalFundUnits(res.data.data.totalUnits)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });

    yup
      .reach(creditCardFormSchema, name)
      .validate(value)
      .then(() => {
        setError({
          initialErrorState,
          [name]: "",
        });
      })
      .catch((err) => {
        setError({
          ...initialErrorState,
          [name]: err.errors,
        });
      });
  };
  const handleCardNumberChange = (e) => {
    const {value} = e.target
    setForm({...form, cardNumber:value})
   }

   const handleExpiryChange = (e) => {
     const {value} = e.target
     setForm({...form, cardDate:value})
   }

   const handleCVCChange = (e) => {
     const {value} = e.target
     setForm({...form, cvc:value})
   }
 
   const spreedlyDeliveryQuery = () => {
    let request = {
      spreedlyPaymentToken:spreedlyToken,
      account:JSON.parse(billingInfo),
      amount:id,
      referenceID:reservation,
      ip:ip
    }
    axiosWithAuth().post('/fund/spreedlyReceiverDelivery', request).then(response => {
      console.log(response.data.id)
      setWyreOrderID(response.data.id)
      pollOrderStatus(response.data.id)
    }).catch(err => {
      setPage('errorstate')
      console.log('spreeedlyreceiverdelivery error', err)
    })
  }


  const onSubmit = (e) => {
    e.preventDefault();
    console.log('reservation in submit', reservation)
    const request = {
      ccInfo:form,
      account:JSON.parse(billingInfo),
      ip:ip,
      reservation:reservation
    }
    console.log('account', request)
    axiosWithAuth().post("/fund/spreedlycardcreation", request).then(response => {
      console.log('response_data', response.data)
      setSpreedlyToken(response.data)
      setPage('loading')
      spreedlyDeliveryQuery()
    })
    .catch(error => {
      console.log(error)
    })
    
  };

  const successfulWyrePayment = (data) => {
    axiosWithAuth().post('/fund/successfulwyrepayment', data).then(res => {
      console.log(res)
    })
    .catch(err => {
      console.log(err)
    })
  }

  const pollOrderStatus = (wyreID) => {
    let interval
    interval = setInterval(() => {
      axiosWithAuth().post('/fund/wyreorderstatus', {id:wyreID}).then(response => {
        if(response.data.status === "RUNNING_CHECKS" && response.data.authCodesRequested === false){
         console.log('running checks and auth = false', response.data)
         setLoadingText('Running checks')
        } else if (response.data.status === "RUNNING_CHECKS" && response.data.authCodesRequested === true){
          clearInterval(interval)
          console.log('running check + auth = true')
          setLoadingText('Checking authentication codes')
          getWyreAuthorizationScope(response.data.id)
          setWyreOrderStatus({
            authCodes:response.data.authCodesRequested,
            status:response.data.status,
            id:response.data.id,
            sourceAmountWithoutFees:response.data.sourceAmountWithoutFees,
            sourceAmount:response.data.sourceAmount
          })
        } else if (response.data.status === "PROCESSING"){
            console.log('processing')
            setLoadingText('Processing payment')
        } else if (response.data.status === "COMPLETE"){
          console.log('happy trail COMPLETE', response.data)
          let data = {
            fundUnits:totalUnitsPurchased,
            managerUnits:fundUnits,
            userUnits:userUnits,
            firebaseID:user.id,
            orderID:response.data.id,
            ip:ip,
            amountIncludingFees:response.data.usdSourceAmount,
            sourceAmount:response.data.usdPurchaseAmount
          }
          successfulWyrePayment(data)
        } else {
          clearInterval(interval)
          setPage('errorstate')
          console.log('ELSE', response.data)
        }
      })
      .catch(err => {
        clearInterval(interval)
        console.log(err)
      })
    }, 1500)
  }


const getWyreAuthorizationScope = (id) => {
  axiosWithAuth().post('/fund/wyreauthorizationstatus', {id:id}).then(response => {
    console.log('getwyreauthscope response', response.data)
    setAuthorizationStatus({
      orderID:response.data.orderID,
      scope:response.data.scope,
      reservation:reservation,
      fundUnits:fundUnits,
      userUnits:userUnits,
      totalUnitsPurchased:totalUnitsPurchased,
      firebaseID:user.id
    })
    console.log('auth status', authorizationStatus)
    Cookies.set('authStatus', JSON.stringify({
      orderID:response.data.orderID,
      scope:response.data.scope,
      reservation:reservation,
      fundUnits:fundUnits,
      userUnits:userUnits,
      totalUnitsPurchased:totalUnitsPurchased,
      firebaseID:user.id,
      ip:ip,
      sourceAmount:wyreOrderStatus.sourceAmount,
      sourceAmountWithoutFees:wyreOrderStatus.sourceAmountWithoutFees
    }))
    history(`/subscription/${id}/authorization`)
  })
.catch(err => {
  console.log(err)
})
}





console.log('reservationQuote', reservationQuote)
const fees = reservationQuote.sourceAmount - reservationQuote.sourceAmountWithoutFees
console.log('fees', fees)
const totalAmount = reservationQuote.sourceAmount
const totalUnitsPurchased = (totalAmount / unitValue)
const userUnits = totalUnitsPurchased * 0.95
const fundUnits = totalUnitsPurchased * 0.05
// useEffect(() => {

// }, [wyreOrderID])



console.log('total amounttttt', totalAmount)
const errorModalState = {
  errorMessage:'There was an issue processing your card, please double check your information and try again.',
  errorButtonText:'RETRY'
}

const errorStateOnClick = (e) => {
  e.preventDefault()
  setTotalFundUnits(null)
  setForm(initialCreditCardState)
  setReservation(null)
  setAuthorizationStatus({})
  setReservationQuote({})
  setWyreOrderID(null)
  setIP(null)
  setWyreOrderStatus({})
  setWyreOrderID(null)
  setSpreedlyToken(null)
  setSubmitted(false)
  setError(initialErrorState)
  setErrorState(false)
  Cookies.set('subscriptionID', id)
  history("/subscriptionpage/default")
}
return (
    // <div className="flex border-8 flex-col h-full w-full">
      <div>
        {
          page === "errorstate" ?
          <div  className="overflow-y-auto overflow-x-hidden m-auto mt-48 z-50 md:inset-0 h-modal md:h-full">
    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-6 text-center">
                <svg className="mx-auto mb-4 w-14 h-14 text-red-400 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{errorModalState.errorMessage}</h3>
                <button type="button" onClick={(e) => {errorStateOnClick(e)}} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                    {errorModalState.errorButtonText}
                </button>
            </div>
        </div>
    </div>
</div>
          :
          <></>
        }
      {
             page === "loading" ?
             <div className='mt-32'>
           
             <i class="fa-solid fa-spinner-third text-6xl text-indigo-400 animate-spin "></i>
            
             <h2 className="text-4xl mt-16 text-indigo-500 font-semibold ">{loadingText}<span className="animate-pulse">.</span><span className="animate-pulse"><span className="animate-pulse">.</span></span><span className="animate-pulse">.</span></h2>
             </div>
             :
             <></>
      }

            {
              page === 'creditcard' ?
              <div className="">
        <h2 className="text-4xl mt-16 text-indigo-500 font-semibold ">
        Step 3: Enter card information
      </h2>
<div className="flex items-center flex-col bg-white w-full mt-10 md:w-6/7 rounded-2xl  shadow-xl rounded-sm border border-slate-200 transform duration-200 hover:scale-105 hover:shadow-2xl">
              <div className="text-base font-bold text-gray-500 mb-4 tracking-wide mt-4">
                   Credit Card
                 </div>
                 <form onSubmit={(e) => onSubmit(e)}>
     
                 <CreditCardInput
                 containerClassName="w-11/12 text-lg py-2 mb-10 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                 cardNumberInputProps={{ value:form.cardNumber, onChange: (e) => handleCardNumberChange(e)}}
                 cardExpiryInputProps={{value:form.cardDate, onChange:(e) => handleExpiryChange(e)}}
                 cardCVCInputProps={{value: form.cvc, onChange:(e) => handleCVCChange(e)}}
                 />
                 <div className="flex mt-8 flex-col items-start w-full">
                 <div className="flex w-full items-start flex-row mb-4 justify-between">
                 <p className="text-2xl font-sans text-indigo-500 border-b-2">Units:</p><span className="text-slate-700 border-b-2 text-2xl">{userUnits.toFixed(4)}</span>
                   </div>
                 </div>
                  <div className="flex w-full items-start flex-row mb-4 justify-between ">
                 <p className="text-2xl text-indigo-500 font-sans border-b-2">Subscription:</p><span className="text-2xl border-b-2 text-slate-700">{id}$</span>
                 </div>
                 <div className="flex w-full items-start flex-row mb-4 justify-between">
                 <p className="text-2xl text-indigo-500 font-sans border-b-2">Fees:</p><span className="text-2xl border-b-2 text-slate-700">{fees.toFixed(2)}$</span>
                 </div>
                 <div className="flex w-full items-start flex-row mb-4 justify-between">
                 <p className="text-2xl text-indigo-500 font-sans border-b-2">Total:</p><span className="text-2xl border-b-2 text-slate-700">{totalAmount}$</span>
                 </div>
                 <div className="mb-8 flex flex-row">
                 <button
                   className="bg-red-600 text-gray-100 p-4 mt-8 w-full mb-2 rounded-full tracking-wide
                   font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                   shadow-lg"
                   type="button"
                   value="cancel"
                   name="cancel"
                   onClick={()=> {history('/subscriptionpage/default')}}
                   >
                   CANCEL
                 </button>
                 <button
                   className="bg-indigo-800 text-gray-100 p-4 ml-2 mt-8 w-full mb-2 rounded-full tracking-wide
                   font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                   shadow-lg"
                   type="submit"
                   name="confirm"
                   >
                   CONFIRM
                 </button>
               </div>
                   </form>
           </div>
            </div>
              :
              <>
              </>
            }
            </div>
            
             
  
  
    
  );
}
