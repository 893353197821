import axios from "axios";
import Cookies from 'js-cookie'
export const axiosWithAuth = () => {
  const token = Cookies.get("auth_token");
  const devURL = "http://localhost:3500"
  const prodURL = "https://ttmm-be.com"
  return axios.create({
    headers: {
      Authorization: token,
      'Access-Control-Allow-Origin':"*"
    },
    baseURL:prodURL,
  });
};
//"https://ttmm-be.com"
//"http://localhost:3500"