import React, {useState, useEffect} from "react";
import { Route, Redirect, Outlet, Navigate } from "react-router-dom";
import {useSelector} from "react-redux";
import {baseAxiosCall} from './baseAxiosCall'
import Cookies from 'js-cookie'
//FIX THIS BEFORE RELEASE 1
const PrivateRoute = () => {
  const isLoggedIn = useSelector(state => state.user.loggedIn);
  const [isVerified, setIsVerified] = useState(null)
  const redirectURL = "/"




  //axios call to verify token endpoint backend endpoint
  // axios call returns a boolean of "true" if verified
  // if that response == true then return <component> 
  const axiosCall = () => {
    baseAxiosCall()
     .post('/auth/verifytoken', {token})
     .then((res) => {
       setIsVerified(res.data.data)
     })
     .catch(err => {
       setIsVerified(false)
     })
  }
  useEffect(() => {
    axiosCall()
  }, [])
  const token = Cookies.get("auth_token")

  if(isVerified === false){
    return <Navigate to={redirectURL} replace/>
  }

  return <Outlet/> 
};

export default PrivateRoute;