import {
    getBitcoinUSDPrice,
    getEthereumUSDPrice,
    getBNBUSDPrice,
    getCAKEUSDPrice,
    getCryptoAmount,
    getPOLYUSDPrice,
    getOHLCVBNB,
    getOHLCVBTC,
    getOHLCVCAKE,
    getOHLCVETH,
    getOHLCVMATIC,
    getF2PoolLTCData,
    getLitecoinUSDPrice
  } from "../../utils/axiosCalls"
  
  export const types = {
    GET_BTC_START: "GET_BTC_START",
    GET_BTC_SUCCESS: "GET_BTC_SUCCESS",
    GET_BTC_RESOLVE: "GET_BTC_RESOLVE",
    GET_BTC_ERROR: "GET_BTC_ERROR",
    GET_LTC_START: "GET_LTC_START",
    GET_LTC_SUCCESS: "GET_LTC_SUCCESS",
    GET_LTC_RESOLVE: "GET_LTC_RESOLVE",
    GET_LTC_ERROR: "GET_LTC_ERROR",
    GET_ETH_START: "GET_ETH_START",
    GET_ETH_SUCCESS: "GET_ETH_SUCCESS",
    GET_ETH_RESOLVE: "GET_ETH_RESOLVE",
    GET_ETH_ERROR: "GET_ETH_ERROR",
    GET_CAKE_START: "GET_CAKE_START",
    GET_CAKE_SUCCESS: "GET_CAKE_SUCCESS",
    GET_CAKE_RESOLVE: "GET_CAKE_RESOLVE",
    GET_CAKE_ERROR: "GET_CAKE_ERROR",
    GET_BNB_START: "GET_BNB_START",
    GET_BNB_SUCCESS: "GET_BNB_SUCCESS",
    GET_BNB_RESOLVE: "GET_BNB_RESOLVE",
    GET_BNB_ERROR: "GET_BNB_ERROR",
    GET_POLY_START: "GET_POLY_START",
    GET_POLY_SUCCESS: "GET_POLY_SUCCESS",
    GET_POLY_RESOLVE: "GET_POLY_RESOLVE",
    GET_POLY_ERROR: "GET_POLY_ERROR",
    RESET_CRYPTO_STATE: "RESET_CRYPTO_STATE",
    GET_CRYPTO_AMOUNT_START: "GET_CRYPTO_AMOUNT_START",
    GET_CRYPTO_AMOUNT_SUCCESS: "GET_CRYPTO_AMOUNT_SUCCESS",
    GET_CRYPTO_AMOUNT_ERROR: "GET_CRYPTO_AMOUNT_ERROR",
    GET_CRYPTO_AMOUNT_RESOLVED: "GET_CRYPTO_AMOUNT_RESOLVED",
    GET_BTC_OHLCV_START: "GET_BTC_OHLCV_START",
    GET_BTC_OHLCV_SUCCESS: "GET_BTC_OHLCV_SUCCESS",
    GET_BTC_OHLCV_ERROR: "GET_BTC_OHLCV_ERROR",
    GET_BNB_OHLCV_START: "GET_BNB_OHLCV_START",
    GET_BNB_OHLCV_SUCCESS: "GET_BNB_OHLCV_SUCCESS",
    GET_BNB_OHLCV_ERROR: "GET_BNB_OHLCV_ERROR",
    GET_ETH_OHLCV_START: "GET_ETH_OHLCV_START",
    GET_ETH_OHLCV_SUCCESS: "GET_ETH_OHLCV_SUCCESS",
    GET_ETH_OHLCV_ERROR: "GET_ETH_OHLCV_ERROR",
    GET_CAKE_OHLCV_START: "GET_CAKE_OHLCV_START",
    GET_CAKE_OHLCV_SUCCESS: "GET_CAKE_OHLCV_SUCCESS",
    GET_CAKE_OHLCV_ERROR: "GET_CAKE_OHLCV_ERROR",
    GET_MATIC_OHLCV_START: "GET_MATIC_OHLCV_START",
    GET_MATIC_OHLCV_SUCCESS: "GET_MATIC_OHLCV_SUCCESS",
    GET_MATIC_OHLCV_ERROR: "GET_MATIC_OHLCV_ERROR",
    GET_F2PLTC_SUCCESS:"GET_F2PLTC_SUCCESS",
    GET_F2PLTC_START:"GET_F2PLTC_START",
    GET_F2PLTC_ERROR:"GET_F2PLTC_ERROR"
  };
  
  const cryptoState = {
    btcUSDPrice: 0,
    ethUSDPrice: 0,
    bnbUSDPrice: 0,
    ltcUSDPrice:0,
    cakeUSDPrice: 0,
    polyUSDPrice: 0,
    cakeAmount: 0,
    ethAmount: 0,
    btcAmount: 0,
    polyAmount: 0,
    status: "idle",
    error: null,
    isLoading: false,
    btcOHLCV: [],
    bnbOHLCV: [],
    ethOHLCV: [],
    cakeOHLCV: [],
    maticOHLCV: [],
    f2poolLTC:[]
  };
  
  export const cryptoActions = {
    getBitcoinUSDPriceThunk: () => (dispatch) => {
      dispatch({ type: types.GET_BTC_START });
      getBitcoinUSDPrice()
        .then((res) => {
          dispatch({ type: types.GET_BTC_SUCCESS, payload: res });
        })
        .catch((err) => {
          dispatch({ type: types.GET_BTC_ERROR, payload: err.message });
        })
        .finally((res) => {
          dispatch({ type: types.GET_BTC_RESOLVE });
        });
    },
    getLitecoinUSDPriceThunk: () => (dispatch) => {
      dispatch({ type: types.GET_LTC_START });
      getLitecoinUSDPrice()
        .then((res) => {
          dispatch({ type: types.GET_LTC_SUCCESS, payload: res });
        })
        .catch((err) => {
          dispatch({ type: types.GET_LTC_ERROR, payload: err.message });
        })
        .finally((res) => {
          dispatch({ type: types.GET_LTC_RESOLVE });
        });
    },
    getEthereumUSDPriceThunk: () => (dispatch) => {
      dispatch({ type: types.GET_ETH_START });
      getEthereumUSDPrice()
        .then((res) => {
          dispatch({ type: types.GET_ETH_SUCCESS, payload: res });
        })
        .catch((err) => {
          dispatch({ type: types.GET_ETH_ERROR, payload: err.message });
        })
        .finally((res) => {
          dispatch({ type: types.GET_ETH_RESOLVE });
        });
    },
  
    getBNBUSDPriceThunk: () => (dispatch) => {
      dispatch({ type: types.GET_BNB_START });
      getBNBUSDPrice()
        .then((res) => {
          dispatch({ type: types.GET_BNB_SUCCESS, payload: res });
        })
        .catch((err) => {
          dispatch({ type: types.GET_BNB_ERROR, payload: err.message });
        })
        .finally((res) => {
          dispatch({ type: types.GET_BNB_RESOLVE });
        });
    },
    getCAKEUSDPriceThunk: () => (dispatch) => {
      dispatch({ type: types.GET_CAKE_START });
      getCAKEUSDPrice()
        .then((res) => {
          dispatch({ type: types.GET_CAKE_SUCCESS, payload: res });
        })
        .catch((err) => {
          dispatch({ type: types.GET_CAKE_ERROR, payload: err.message });
        })
        .finally((res) => {
          dispatch({ type: types.GET_CAKE_RESOLVE });
        });
    },
    getPOLYUSDPriceThunk: () => (dispatch) => {
      dispatch({ type: types.GET_POLY_START });
      getPOLYUSDPrice()
        .then((res) => {
          dispatch({ type: types.GET_POLY_SUCCESS, payload: res });
        })
        .catch((err) => {
          dispatch({ type: types.GET_POLY_ERROR, payload: err.message });
        })
        .finally((res) => {
          dispatch({ type: types.GET_POLY_RESOLVE });
        });
    },
    resetCryptoStateThunk: () => (dispatch) => {
      dispatch({ type: types.RESET_CRYPTO_STATE });
    },
    getCurrentCryptoAmountThunk: () => (dispatch) => {
      dispatch({ type: types.GET_CRYPTO_AMOUNT_START });
      getCryptoAmount()
        .then((res) => {
          dispatch({
            type: types.GET_CRYPTO_AMOUNT_SUCCESS,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({ type: types.GET_CRYPTO_AMOUNT_ERROR, payload: err.message });
        })
        .finally(() => {
          dispatch({ type: types.GET_CRYPTO_AMOUNT_RESOLVED });
        });
    },
    getOHLCVBNBThunk: () => (dispatch) => {
      dispatch({ type: types.GET_BNB_OHLCV_START });
      getOHLCVBNB()
        .then((res) => {
          console.log(res);
  
          dispatch({
            type: types.GET_BNB_OHLCV_SUCCESS,
            payload: res.data.ohlcv,
          });
        })
        .catch((err) => {
          dispatch({ type: types.GET_BNB_ERROR, payload: err });
        });
    },
    getOHLCVBTCThunk: () => (dispatch) => {
      dispatch({ type: types.GET_BTC_OHLCV_START });
      getOHLCVBTC()
        .then((res) => {
          console.log(res);
          dispatch({
            type: types.GET_BTC_OHLCV_SUCCESS,
            payload: res.data.ohlcv,
          });
        })
        .catch((err) => {
          dispatch({ type: types.GET_BTC_ERROR, payload: err });
        });
    },
    getOHLCVETHThunk: () => (dispatch) => {
      dispatch({ type: types.GET_ETH_OHLCV_START });
      getOHLCVETH()
        .then((res) => {
          console.log(res);
          dispatch({
            type: types.GET_ETH_OHLCV_SUCCESS,
            payload: res.data.ohlcv,
          });
        })
        .catch((err) => {
          dispatch({ type: types.GET_ETH_ERROR, payload: err });
        });
    },
    getOHLCVCAKEThunk: () => (dispatch) => {
      dispatch({ type: types.GET_CAKE_OHLCV_START });
      getOHLCVCAKE()
        .then((res) => {
          console.log(res);
          dispatch({
            type: types.GET_CAKE_OHLCV_SUCCESS,
            payload: res.data.ohlcv,
          });
        })
        .catch((err) => {
          dispatch({ type: types.GET_CAKE_ERROR, payload: err });
        });
    },
    getOHLCVMATICThunk: () => (dispatch) => {
      dispatch({ type: types.GET_MATIC_OHLCV_START });
      getOHLCVMATIC()
        .then((res) => {
          console.log(res);
          dispatch({
            type: types.GET_MATIC_OHLCV_SUCCESS,
            payload: res.data.ohlcv,
          });
        })
        .catch((err) => {
          dispatch({ type: types.GET_MATIC_ERROR, payload: err });
        });
    },
    getF2PoolLTCThunk: () => (dispatch) => {
      dispatch({type:types.GET_F2PLTC_START })
      getF2PoolLTCData()
    }
  };
  
  const cryptoReducer = (state = cryptoState, action) => {
    switch (action.type) {
      case types.GET_BTC_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_BTC_SUCCESS:
        return {
          ...state,
          btcUSDPrice: action.payload,
          status: "get/success",
        };
      case types.GET_BTC_ERROR:
        return {
          ...state,
          status: "get/error",
          error: action.payload,
        };
      case types.GET_BTC_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
        case types.GET_LTC_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_LTC_SUCCESS:
        return {
          ...state,
          ltcUSDPrice: action.payload,
          status: "get/success",
        };
      case types.GET_LTC_ERROR:
        return {
          ...state,
          status: "get/error",
          error: action.payload,
        };
      case types.GET_LTC_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.GET_ETH_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_ETH_SUCCESS:
        return {
          ...state,
          ethUSDPrice: action.payload,
          status: "get/success",
        };
      case types.GET_ETH_ERROR:
        return {
          ...state,
          status: "get/error",
          error: action.payload,
        };
      case types.GET_ETH_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.GET_CAKE_START:
        return {
          ...state,
          status: "get/pending",
          isLoading: true,
        };
      case types.GET_CAKE_SUCCESS:
        return {
          ...state,
          cakeUSDPrice: action.payload,
          status: "get/success",
          isLoading: false,
        };
      case types.GET_CAKE_ERROR:
        return {
          ...state,
          status: "get/error",
          error: action.payload,
          isLoading: false,
        };
      case types.GET_CAKE_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.GET_BNB_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_BNB_SUCCESS:
        return {
          ...state,
          bnbUSDPrice: action.payload,
          status: "get/success",
        };
      case types.GET_BNB_ERROR:
        return {
          ...state,
          status: "get/error",
          error: action.payload,
        };
      case types.GET_BNB_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.GET_POLY_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_POLY_SUCCESS:
        return {
          ...state,
          polyUSDPrice: action.payload,
          status: "get/success",
        };
      case types.GET_POLY_ERROR:
        return {
          ...state,
          status: "get/error",
          error: action.payload,
        };
      case types.GET_POLY_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.GET_CRYPTO_AMOUNT_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_CRYPTO_AMOUNT_SUCCESS:
        return {
          ...state,
          status: "get/success",
          ethAmount: action.payload.eth,
          cakeAmount: action.payload.cake,
          btcAmount: action.payload.btc,
          polyAmount: action.payload.poly,
        };
      case types.GET_CRYPTO_AMOUNT_ERROR:
        return {
          ...state,
          status: "get/error",
          error: action.payload,
        };
      case types.GET_CRYPTO_AMOUNT_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
  
      case types.GET_BNB_OHLCV_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_BNB_OHLCV_SUCCESS:
        return {
          ...state,
          status: "get/success",
          bnbOHLCV: action.payload,
        };
      case types.GET_BNB_OHLCV_ERROR:
        return {
          ...state,
          status: "get/failure",
          error: action.payload,
        };
      case types.GET_BTC_OHLCV_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_BTC_OHLCV_SUCCESS:
        return {
          ...state,
          status: "get/success",
          btcOHLCV: action.payload,
        };
      case types.GET_BTC_OHLCV_ERROR:
        return {
          ...state,
          status: "get/failure",
          error: action.payload,
        };
      case types.GET_ETH_OHLCV_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_ETH_OHLCV_SUCCESS:
        return {
          ...state,
          status: "get/success",
          ethOHLCV: action.payload,
        };
      case types.GET_ETH_OHLCV_ERROR:
        return {
          ...state,
          status: "get/failure",
          error: action.payload,
        };
      case types.GET_CAKE_OHLCV_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_CAKE_OHLCV_SUCCESS:
        return {
          ...state,
          status: "get/success",
          cakeOHLCV: action.payload,
        };
      case types.GET_CAKE_OHLCV_ERROR:
        return {
          ...state,
          status: "get/failure",
          error: action.payload,
        };
      case types.GET_MATIC_OHLCV_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_MATIC_OHLCV_SUCCESS:
        return {
          ...state,
          status: "get/success",
          maticOHLCV: action.payload,
        };
      case types.GET_MATIC_OHLCV_ERROR:
        return {
          ...state,
          status: "get/failure",
          error: action.payload,
        };
      case types.RESET_CRYPTO_STATE:
        return cryptoState;
  
      default: {
        return state;
      }
    }
  };
  export default cryptoReducer;
  