import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import * as yup from "yup";

import { userActions } from "../../store/ducks/userDuck";
import FormWrapper from "../../partials/forms/FormWrapper";
import signupSchema from "../../utils/yupSchemas/signupSchema";

const initialUserState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  confirmPassword: "",
  company: "",
  metaData:{
    addressLine1: "",
    addressLine2: "",
    state: "",
    city: "",
    zipCode: "",
    ipAddress: "",
    subscriptionStatus:'unpaid'
  },
  role: "freeUser",
  password: "",
  wallets:{
    ethWallet:'',
    btcWallet:'',
    dogeWallet:'',
    ltcWallet:''
  },
  initBalance: 0,
  currentCompoundBalance: 0,
  units: 0,
  created_at: "",
};

const initialMetaData = {
    addressLine1: "",
    addressLine2: "",
    state: "",
    city: "",
    zipCode: "",
    ipAddress: "",
}

const initialErrors = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phoneNumber: "",
  addressLine1: "",
  state: "",
  city: "",
  zipCode: "",
};

const confirmPassword = "";
const initialPasswordError = {
  confirmPassword: "",
};

export default function SignUp() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [form, setForm] = useState(initialUserState);
  const [meta, setMeta] = useState(initialMetaData)
  const [errors, setErrors] = useState(initialErrors);
  const [signupError, setSignupError] = useState(null);
  const [passwordError, setPasswordError] = useState(initialPasswordError);
  const [confirmedPassword, setConfirmedPassword] = useState(confirmPassword);

  const passwordValidation = () => {
    if (form.password !== "undefined" && form.confirmPassword !== "undefined") {
      if (form.password !== form.confirmPassword) {
        setPasswordError({
          ...initialPasswordError,
          confirmPassword: "Passwords do not match",
        });
      }
    } else {
      setPasswordError({
        ...initialPasswordError,
        confirmPassword: "",
      });
    }
  };

  const handleMetaOnChange = (e) => {
    const {name, value} = e.target
    setMeta({
      ...meta, [name]:value
    })
    yup
      .reach(signupSchema, name)
      .validate(value)
      .then(() => {
        setErrors({
          initialErrors,
          [name]: "",
        });
      })
      .catch((err) => {
        setErrors({
          ...initialErrors,
          [name]: err.errors,
        });
      });
  }

  const handleSignup = (e) => {
    e.preventDefault();
    dispatch(userActions.registerThunk(form));
    setTimeout(() => {
     history('/login')
    }, 300);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // debugger
    if(form[name] !== undefined){
      setForm({ ...form, [name]: value });
    } else if(form.metaData[name] !== undefined){
      setForm({...form, metaData:{...form.metaData, [name]:value}})
    }
    
    yup
      .reach(signupSchema, name)
      .validate(value)
      .then(() => {
        setErrors({
          initialErrors,
          [name]: "",
        });
      })
      .catch((err) => {
        setErrors({
          ...initialErrors,
          [name]: err.errors,
        });
      });
  };


  return (
    <div className="bg-dashboardBackGroundGray  overflow-x-hidden">
      <div className="flex flex-row items-center mt-36 justify-center h-screen">
        <div className="xxs:w-5/6 xs:w-4/5 sm:w-4/5 lg:w-3/5 xl:w-3/5 2xl:w-1/2 ">
          <FormWrapper mb={"12"}>
            <div className="px-4 pt-8 max-w-2xl">
              <div className="cursor-pointer flex items-center">
                <i class="fad fa-moon text-indigo-800 text-2xl w-10"></i>
                <div className="text-2xl text-indigo-800 tracking-wide ml-2 font-semibold">
                  Lunam
                </div>
              </div>
            </div>
            <h2 class="text-center mt-12 text-4xl text-indigo-800 font-display font-semibold lg:text-left xl:text-5xl xl:text-bold">
              Sign up
            </h2>
            <div className="mt-12">
              <form onSubmit={(e) => {handleSignup(e)}}>
                <div>
                  <div class="text-sm font-bold text-gray-400 tracking-wide">
                    Email Address
                  </div>
                  <input
                    class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    name="email"
                    value={form.email}
                    onChange={(e) => handleChange(e)}
                    placeholder="nemo@gmail.com"
                  />
                </div>
                <div>
                  <div class="text-sm font-bold text-gray-400 tracking-wide">
                    Phone Number
                  </div>
                  <input
                    class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    name="phoneNumber"
                    value={form.phoneNumber}
                    onChange={(e) => handleChange(e)}
                    placeholder="i.e: 9043214112"
                  />
                </div>
                <div className="mt-8 flex flex-row items-center justify-center w-full ">
                  <div className="w-1/2">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      First Name
                    </div>
                    <input
                      class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      type="fName"
                      placeholder="Enter your first name"
                      name="firstName"
                      value={form.firstName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="w-1/2 ml-2">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      Last Name
                    </div>
                    <input
                      class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      type="lName"
                      placeholder="Enter your last name"
                      name="lastName"
                      value={form.lastName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div class="mt-8">
                  <div class="flex justify-center items-center">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      Address line 1
                    </div>
                  </div>
                  <input
                    class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="metaData.addressLine1"
                    placeholder="42 Wallaby Way"
                    name="addressLine1"
                    value={form.metaData.addressLine1}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div class="mt-8">
                  <div class="flex justify-center items-center">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      Address Line 2
                    </div>
                  </div>
                  <input
                    class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="address2"
                    placeholder="Apartment 8"
                    name="addressLine2"
                    value={form.metaData.addressLine2}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="mt-8 flex flex-row items-center justify-center w-full ">
                  <div className="w-1/3">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      State
                    </div>
                    <input
                      class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      type="state"
                      placeholder="example: FL"
                      name="state"
                      value={meta.state}
                      onChange={(e) => handleMetaOnChange(e)}
                    />
                  </div>
                  <div className="w-1/3 ml-2">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      City
                    </div>
                    <input
                      class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      type="city"
                      placeholder="Jacksonville"
                      name="city"
                      value={meta.city}
                      onChange={(e) => handleMetaOnChange(e)}
                    />
                  </div>
                  <div className="w-1/3 ml-2">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      Zip Code
                    </div>
                    <input
                      class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      type="zipCode"
                      placeholder="32034"
                      name="zipCode"
                      value={meta.zipCode}
                      onChange={(e) => handleMetaOnChange(e)}
                    />
                  </div>
                </div>
                <div class="mt-8">
                  <div class="flex justify-center items-center">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      Password
                    </div>
                  </div>
                  <input
                    class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="password"
                    placeholder="Enter your password"
                    name="password"
                    value={form.password}
                    onKeyUp={passwordValidation}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div class="mt-8">
                  <div class="flex justify-center items-center">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      Confirm Password
                    </div>
                  </div>
                  <input
                    class="w-full mb-8 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="confirmPassword"
                    placeholder="Confirm your password"
                    name="confirmPassword"
                    onKeyUp={passwordValidation}
                    value={form.confirmPassword}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="text-red-500">{errors.email}</div>
                <div className="text-red-500">{errors.phoneNumber}</div>
                <div className="text-red-500">{errors.fname}</div>
                <div className="text-red-500">{errors.lname}</div>
                <div className="text-red-500">{errors.addressLine1}</div>
                <div className="text-red-500">{errors.addressLine2}</div>
                <div className="text-red-500">{errors.state}</div>
                <div className="text-red-500">{errors.city}</div>
                <div className="text-red-500">{errors.zipCode}</div>
                <div className="text-red-500">{errors.password}</div>
                {/* <div className="text-red-500">
                  {passwordError.confirmPassword}
                </div> */}
                <div class="">
                  <button
                    class="bg-indigo-800 text-gray-100 p-4 w-3/5 mb-10 rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg"
                    type="submit"
                  >
                    SIGN UP
                  </button>
                </div>
              </form>
              <div class="mt-2 mb-4 text-sm font-display font-semibold text-gray-700 text-center">
                Already have an account?{" "}
                <Link
                  class="cursor-pointer text-indigo-700 hover:text-indigo-800"
                  to="/login"
                >
                  Login
                </Link>
              </div>
            </div>
          </FormWrapper>
        </div>
      </div>
    </div>
  );
}
