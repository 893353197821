import React from 'react'
import { Link } from 'react-router-dom'
import EditMenu from './EditMenu'
import IndividualBalanceGraph from './individualBalanceGraph'
let HashMap = require('hashmap')
export default function LineGraphWrapper({data, currentInvestorAmount, growth, graphStateSetter, dataScope}) {

let setPillColor = new HashMap()

setPillColor.set('neutral', 'bg-yellow-500')
setPillColor.set('negative', 'bg-red-500')
setPillColor.set('positive', 'bg-green-500')

let pillColor 
let numberToBeFormatted = currentInvestorAmount
let formattedNumber = numberToBeFormatted.toLocaleString(undefined, { maximumFractionDigits: 2 })
const getPillColor = () => {
  if(growth > 0){
    return pillColor = setPillColor.get('positive')
  } else if(growth < -5){
    return pillColor = setPillColor.get('negative')
  } else{
    return  pillColor= setPillColor.get('neutral')
  }
}

getPillColor()
  return (
    <div className="flex flex-col w-5/6 md:w-6/7 sm:col-1/2 xl:col-1/2 xs:mt-8 md:mt-6 bg-white shadow-lg rounded-sm border border-slate-200 lg:mt-16">
        <div className="px-5 pt-5">
        <header className="flex justify-between items-end mb-2">
        <EditMenu className="relative inline-flex">
        <li>
        <button className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3" onClick={(string) => graphStateSetter("all_time_data")}>All time</button>
        </li>
        <li>
        <button className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3" onClick={(string) => graphStateSetter("six_month_data")}>6 months</button>
        </li>
        <li>
        <button className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3" onClick={(string) => graphStateSetter("thirty_day_data")}>1 month</button>
        </li>
        </EditMenu>
        </header>
        <h2 className="text-lg font-bold text-gray-800 mb-2">Current {dataScope} Value</h2>
        <div className="flex justify-center">
        <div className="ml-6 text-4xl font-semibold text-gray-900 ">${formattedNumber}</div>
        {/* <div className={`text-sm font-semibold text-white px-1.5 ${pillColor} rounded-full mb-12 ml-2`}>{growth.toFixed(2)}%</div> */}
        </div>
        </div>
    <div className="grow">
        <IndividualBalanceGraph data={data} />
    </div>

    </div>
  )
}
