import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { userActions } from "../store/ducks/userDuck";
import SideBar from "../partials/navbar/SideBar";
import Header from "../partials/navbar/Header";
export default function Settings() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const currentUser = useSelector((state) => state.user.user);

  console.log(currentUser)
const { company, email, wallets:{ethWallet, btcWallet, ltcWallet, dogeWallet}, firstName, lastName, metaData:{addressLine1, addressLine2, city, state, zipCode} } = currentUser
 console.log(ethWallet, btcWallet, ltcWallet, dogeWallet)
//copy for future
const currentUserInitialState = {
    company,
    email,
    ethWallet,
    firstName,
    lastName,
  addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    btcWallet,
    ltcWallet,
    dogeWallet
  }
  const initialWalletState = {
    ethWallet:currentUser.wallets.ethWallet,
    ltcWallet:ltcWallet,
    dogeWallet:dogeWallet,
    btcWallet:btcWallet
  }
  const [userDetails, setUserDetails] = useState(currentUserInitialState);
  const [walletDetails, setWalletDetails] = useState(initialWalletState)
  const [showSideBar, setShowSideBar] = useState(false);
  const [activeNav, setActiveNav] = useState("userDetails")



  const handleNavClick = (tab) => {
    setActiveNav(tab)
}


const handleWalletChange = (e) => {
  const { name, value} = e.target
  console.log('name', name)
  console.log('value', value)
  setWalletDetails({...walletDetails, [name]:value})
}
console.log(walletDetails)
const handleWalletEdit = (e) => {
  e.preventDefault()
  const userToBeUpdate = {
    id:currentUser.id,
    email:userDetails.email,
    firstName:userDetails.firstName,
    lastName:userDetails.lastName,
    metaData:{
      addressLine1:userDetails.addressLine1,
      addressLine2:userDetails.addressLine2,
      city:userDetails.city,
      state:userDetails.state,
      zipCode:userDetails.zipCode,

    },
    wallets:{
      ethWallet:walletDetails.ethWallet,
      ltcWallet:walletDetails.ltcWallet,
      btcWallet:walletDetails.btcWallet,
      dogeWallet:walletDetails.dogeWallet
    }
  }
  console.log('usertobewallet', userToBeUpdate)
  dispatch(userActions.editUserThunk(userToBeUpdate))
}

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name)
    console.log('here')
    console.log(value)
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleProfileEdit = (e) => {
    e.preventDefault();
    const userToBeUpdate = {
      id:currentUser.id,
      email:userDetails.email,
      firstName:userDetails.firstName,
      lastName:userDetails.lastName,
      metaData:{
        addressLine1:userDetails.addressLine1,
        addressLine2:userDetails.addressLine2,
        city:userDetails.city,
        state:userDetails.state,
        zipCode:userDetails.zipCode,

      },
      wallets:{
      ethWallet:walletDetails.ethWallet,
      ltcWallet:walletDetails.ltcWallet,
      btcWallet:walletDetails.btcWallet,
      dogeWallet:walletDetails.dogeWallet
    }
    }
    console.log('usertobeupdated in settings', userToBeUpdate)
    dispatch(userActions.editUserThunk(userToBeUpdate));
  };

  return (
    <div className=" h-screen flex ">
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div className="relative flex flex-col flex-1 xxxs:overflow-auto">
        <Header
          userFirstName={currentUser.firstName}
          userLastName={currentUser.lastName}
          text=""
        />
        <div className="flex flex-col min-h-screen justify-center items-center bg-dashboardBackGroundGray">
          
            <div className="flex items-start flex-col mb-4 mt-2 bg-white xxxs:w-4/5 xxs:w-3/4 xs:w-3/4 sm:w-3/4 md:w-3/4 rounded-2xl lg:w-2/4 xl:w-2/4 2xl:w-1/2  shadow-xl rounded-sm border border-slate-200 xs:transform duration-200  xs:hover:scale-105 xs:hover:shadow-2xl">
            <nav className="flex flex-row items-center h-full w-full bg-white border-r border-gray-200 w-full rounded-l-md">
                    <div className="divide-y w-full flex flex-row justify-center divide-gray-200">
                        <div className="group w-1/2 cursor-pointer flex items-center pl-3 hover:bg-gray-200 rounded-tl justify-center" onClick={() => handleNavClick("userDetails")}>
                            <i class="text-indigo-600 text-md group-hover:text-gray-800 mt-2 py-2 fas fa-pen fa-lg pr-3"></i>
                            <p class="text-indigo-600 text-md group-hover:text-gray-800 py-2 hidden md:inline">Profile</p>
                        </div>
                        <div className="group w-1/2 cursor-pointer flex items-center pl-3 hover:bg-gray-200 rounded-tl justify-center" onClick={() => handleNavClick("paymentInfo")}>
                            <i class="text-indigo-600 text-md group-hover:text-gray-800 py-2 fa-solid fa-credit-card-front fa-lg pr-3"></i>
                            <p class="text-indigo-600 text-md group-hover:text-gray-800 py-2 hidden md:inline">Wallet Info</p>
                        </div>
                       
                    </div>
                </nav>
                  <div className='w-full'>

                {
                  activeNav === "userDetails"
                  ?
                  <div className="mt-4">
                    <form onSubmit={(e) => {handleProfileEdit(e)}}>
                    <div>
                  <div className="text-sm font-bold text-gray-400 tracking-wide">
                    Email Address
                  </div>
                  <input
                    className="w-4/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    name="email"
                    value={userDetails.email}
                    onChange={(e) => handleChange(e)}
                    placeholder="nemo@gmail.com"
                  />
                </div>
                <div>
                  <div class="text-sm font-bold mt-4 text-gray-400 tracking-wide">
                    Phone Number
                  </div>
                  <input
                    class="w-4/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    name="phoneNumber"
                    value={userDetails.phoneNumber}
                    onChange={(e) => handleChange(e)}
                    placeholder="i.e: 9043214112"
                  />
                </div>
                <div className="mt-8 flex xxxs:flex-col xs:flex-row items-center justify-center w-full ">
                  <div className="xxxs:w-4/5 xs:w-2/5">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      First Name
                    </div>
                    <input
                      class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      type="fName"
                      placeholder="Enter your first name"
                      name="firstName"
                      value={userDetails.firstName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="xxxs:w-4/5 xs:w-2/5 ml-2">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      Last Name
                    </div>
                    <input
                      class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      type="lName"
                      placeholder="Enter your last name"
                      name="lastName"
                      value={userDetails.lastName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div class="mt-8">
                  <div class="flex justify-center items-center">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      Address line 1
                    </div>
                  </div>
                  <input
                    class="w-4/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    name="addressLine1"
                    placeholder="42 Wallaby Way"
                    
                    value={userDetails.addressLine1}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div class="mt-8">
                  <div class="flex justify-center items-center">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      Address Line 2
                    </div>
                  </div>
                  <input
                    class="w-4/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="address2"
                    placeholder="Apartment 8"
                    name="addressLine2"
                    value={userDetails.addressLine2}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="mt-8 flex xxxs:flex-col xxs:flex-row items-center justify-center w-full ">
                  <div className="xxxs:w-2/3 xxs:w-1/3">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      State
                    </div>
                    <input
                      class="w-3/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      type="state"
                      placeholder="example: FL"
                      name="state"
                      value={userDetails.state}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="xxxs:w-2/3 xxs:w-1/3 ml-2">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      City
                    </div>
                    <input
                      class="w-3/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      type="city"
                      placeholder="Jacksonville"
                      name="city"
                      value={userDetails.city}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="xxxs:w-2/3 xxs:w-1/3 ml-2">
                    <div class="text-sm font-bold text-gray-400 tracking-wide">
                      Zip Code
                    </div>
                    <input
                      class="w-3/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      type="zipCode"
                      placeholder="32034"
                      name="zipCode"
                      value={userDetails.zipCode}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <button
                    class="bg-indigo-800 mt-8 text-gray-100 p-4 w-3/5 mb-10 rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg"
                    type="submit"
                  >
                    UPDATE
                  </button>
                    </form>
                  </div>
                  :
                  activeNav === 'paymentInfo'
                  ?
                  <div className="mt-4">
                    <form onSubmit={(e) => {handleWalletEdit(e)}}>
                      <div className="text-md font-bold text-gray-400 tracking-wide">
                        Ethereum Wallet
                      </div>
                      <div className="flex flex-col items-center justify-center">

                      <input
                      className="w-3/5 mb-4 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      value={walletDetails.ethWallet}
                      name="ethWallet"
                      type="ethWallet"
                      onChange={(e)=> handleWalletChange(e)}
                      />
                       <div className="text-md font-bold text-gray-400 tracking-wide">
                        Bitcoin wallet
                      </div>
                      <input
                      className="w-3/5 mb-4 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      value={walletDetails.btcWallet}
                      name="btcWallet"
                      onChange={(e)=> handleWalletChange(e)}
                      />
                       <div className="text-md font-bold text-gray-400 tracking-wide">
                        Doge Coin wallet
                      </div>
                      <input
                      className="w-3/5 mb-4 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      name="dogeWallet"
                      value={walletDetails.dogeWallet}
                      onChange={(e)=> handleWalletChange(e)}
                      />
                       <div className="text-md font-bold text-gray-400 tracking-wide">
                        Litecoin wallet
                      </div>
                      <input
                      className="w-3/5 mb-4 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      name="ltcWallet"
                      value={walletDetails.ltcWallet}
                      onChange={(e)=> handleWalletChange(e)}
                      />
                      <button className="bg-indigo-800 mt-8 text-gray-100 p-4 w-2/5 mb-10 rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg">UPDATE</button>
                      </div>
                      
                    </form>
                  </div>
                  :
                  <></>

                }
                </div>
            </div>
          </div>
        </div>
      </div>
    
  );
}
