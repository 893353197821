import React from 'react'

export default function FormWrapper({children, mb}) {
  return (
    <div className={`flex items-center mb-${mb} flex-col bg-white w-full md:w-6/7   shadow-xl rounded-sm border border-slate-200 transform duration-200 hover:scale-105 hover:shadow-2xl`}>
    {children}   

    </div>
  )
}
