import { axiosWithAuth } from "../utils/axiosWithAuth";
import { baseAxiosCall } from "./baseAxiosCall";
import { useHistory } from "react-router-dom";
import * as yup from 'yup'
import Cookies from 'js-cookie'



import auth from "../index";
import {getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword} from 'firebase/auth'
//MOVE ALL AUTH API REQ TO BACKEND
export const login = async (credentials) => {
  const { email, password } = credentials;
  const newAuth = getAuth()
  let newToken = {}
  signInWithEmailAndPassword(newAuth, email, password)
  .then(userCreds => {
    console.log('userCreds.user authT ========>', userCreds.user.accessToken)
    console.log('userCreds.user rft ========>', userCreds.user.refreshToken)
    Cookies.set('rft', userCreds.user.refreshToken, { expires: 365 })
    Cookies.set('auth_token', userCreds.user.accessToken, { expires: 365 })
  })
  .catch(err => console.log(err))
  
  newToken = Cookies.get('auth_token')
  
  

  return baseAxiosCall()
    .post("/auth/login", {idToken:newToken})
    .then((res) => {
      console.log(res.data.message)
      if(res.data.message === "sucessful login"){
        localStorage.setItem('isTrue', true)
        Cookies.set('isTrue', true)
      }
      Cookies.set("auth_token", res.data.token, { expires: 365 });
      return res.data;
    })
    .catch((err) => {
      console.log("BAD CREDS===> ", err);
      Cookies.set('isTrue', false)
      localStorage.setItem('isTrue', false)
      return err;
    });
};

export const getToken = async () => {
  const token = auth
    .auth()
    .currentUser.getIdToken(false)
    .then(function (idToken) {
      return idToken;
    });

  const rtnToken = await token;
  return token;
};

export const verifyToken = async (token) => {
  auth
    .auth()
    .verifyIdToken(token)
    .then((decodedToken) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};

export const register = async (newUser) => {
  console.log('new user', newUser)
  const {
    email,
    password,
    initBalance,
    currentCompoundBalance,
    metaData,
    phoneNumber,
    role,
    firstName,
    lastName,
    units,
    company,
    wallets,
    created_at
  } = newUser;
  
  // const createUserWithEmailAndPassword = await auth
  //   .auth()
  //   .createUserWithEmailAndPassword(email, password)
  //   .then((userCreds) => {
  //     return { user: userCreds.user, token: userCreds.user.b.b.h , refreshToken: userCreds};
  //   })
  //   .catch((err) => console.log(err));
  const signupAuth = getAuth()
  const validation = createUserWithEmailAndPassword(signupAuth, email, password)
  .then(userCreds => {
    return {id: userCreds.user.uid, accessToken:userCreds.user.accessToken, rft:userCreds.user.refreshToken}
  })


    const createdUser = {
      user:{
        id: (await validation).id,
        email: email,
        firstName:firstName,
        lastName:lastName,
        initBalance:initBalance,
        currentCompoundBalance:currentCompoundBalance,
        units:units,
        metaData:metaData,
        phoneNumber:phoneNumber,
        role:role,
        company:company,
        wallets:wallets,
        created_at:created_at
      },
      accessToken: (await validation).accessToken,
      rft: (await validation).rft
    }
    console.log('createdUser', createdUser)
  return baseAxiosCall()
    .post("/auth/signup", createdUser)
    .then((res) => {
      Cookies.set("auth_token", res.data.token, { expires: 365 });
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getSingleUserByToken = () => {
  return axiosWithAuth()
    .get("/users/user")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getSingleUser = () => {
  return axiosWithAuth()
    .get(`/users/user`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getSingleInvestorById = (id) => {
  return axiosWithAuth()
    .get(`/users/user/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllInvestors = () => {
  return axiosWithAuth()
      .get("/users/all")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
};

export const getUserByUsername = (username) => {
  return axiosWithAuth()
    .get(`/users/username/${username}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const editExistingInvestor = (editedInvestor) => {
  console.log('editedInvestor', editedInvestor)
  return axiosWithAuth()
    .put(`/users/user`, editedInvestor)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteUserById = (userId) => {
  return axiosWithAuth()
    .delete(`/users/${userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getBitcoinUSDPrice = async () => {
  return baseAxiosCall()
    .get("/crypto/btc")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const getLitecoinUSDPrice = async () => {
  return baseAxiosCall()
  .get('/crypto/ltc')
  .then((res) => {
    return res.data;
  })
  .catch((err) => {
    return err;
  });
}
export const getEthereumUSDPrice = async () => {
  return baseAxiosCall()
    .get("/crypto/eth")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getBNBUSDPrice = async () => {
  return baseAxiosCall()
    .get("/crypto/bnb")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getCAKEUSDPrice = async () => {
  return baseAxiosCall()
    .get("/crypto/cake")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const getPOLYUSDPrice = async () => {
  return baseAxiosCall()
    .get("/crypto/matic")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const adminDashboardLogin = async (password) => {
  return axiosWithAuth()
    .post("/users/admin/password", password)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    })
}
export const loginUserWithRFToken = async (userToken) => {
 return axiosWithAuth().post('/auth/loginpersist', {refresh:userToken})
  .then(res => {
    
    return res.data.token
  })
  .catch(err => console.log(err))
}

export const loginUserWithToken = async (token) => {
  return axiosWithAuth().post('/users/loginchecker', {token:token})
  .then(res => {
    return res.data
  })
  .catch(err => {
    return err
  })
}

export const getCryptoAmount = async () => {
  return axiosWithAuth().get('/fund/currentcoinamount')
  .then(res => {
    return res
  })
  .catch(err => {
    return err
})
}


export const getOHLCVBNB = async () => {
  return axiosWithAuth().get('/crypto/ohlcv/bnb')
  .then(res => {
    return res
  })
  .catch(err => {
    return err
  })
}

export const getOHLCVBTC = async () => {
  return axiosWithAuth().get('/crypto/ohlcv/btc')
  .then(res => {
    return res
  })
  .catch(err => {
    return err
  })
}

export const getOHLCVETH = async () => {
  return axiosWithAuth().get('/crypto/ohlcv/eth')
  .then(res => {
    return res
  })
  .catch(err => {
    return err
  })
}

export const getOHLCVCAKE = async () => {
  return axiosWithAuth().get('/crypto/ohlcv/cake')
  .then(res => {
    return res
  })
  .catch(err => {
    return err
  })
}

export const getOHLCVMATIC = async () => {
  return axiosWithAuth().get('/crypto/ohlcv/matic')
  .then(res => {
    return res
  })
  .catch(err => {
    return err
  })
}

export const wyreAccountBillingInfoStart = async (accInfo) => {
return axiosWithAuth().post('/auth/wyreCreation', accInfo)
.then(res => {
  console.log('wyre account creation', res.data)
 return res.data
})
.catch(err => {
  return err
})
}

export const creatingWyreReservation = async (resInfo) => {
  console.log('res info', resInfo)
  return axiosWithAuth().post('/auth/createReservation', resInfo).then(response => {
    console.log(response)
  })
  .catch(error => {
    console.log(error)
  })
}
export const getF2PoolLTCData = async (userInfo) => {
  const poolURL = "https://api.f2pool.com"
  const l7Percentage = userInfo.metaData.f2pool.minerPercentages.L7
  console.log("userInfo", userInfo)
  return baseAxiosCall().get(`${poolURL}/litecoin/${userInfo.metaData.f2pool.id}`)
  .then(data => {
 const currentHashrate = data.data.hashrate * l7Percentage
 let formattedHashData = []
 const amountOfWorkers = data.data.worker_length
const onlineWorkers = data.data.worker_length_online
const historicHashrates = data.data.hashrate_history
for (var key in historicHashrates){
  let formattedNumbers = historicHashrates[key] * l7Percentage
  formattedHashData.push({"date":key, "hashrate":formattedNumbers})
}
const workers = {
  name:data.data.workers[0][0],
  hashrate:data.data.workers[0][1] * l7Percentage,
  hashrate_1h:data.data.workers[0][2] * l7Percentage,
  hashrate_24h:data.data.workers[0][4] * l7Percentage,
  stale_24h:data.data.workers[0][5] * l7Percentage,
  recent_shares:data.data.workers[0][6] * l7Percentage,
  extra:data.data.workers[0][7],
  historic_hashrate:formattedHashData.slice(-31),
  currenthashrate:currentHashrate,
  amountOfWorkers,
  onlineWorkers
}
const returnData = [
  workers
]
return returnData
  })
  .catch(err => {
    return err
  })
}

export const getUserIP = async () => {
  
}