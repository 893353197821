import React, { useState } from "react";
import FormWrapper from "../../partials/forms/FormWrapper";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  sendPasswordResetEmail,
} from "firebase/auth";

const emailInitialState = {
  email: "",
};


export default function PasswordResetWithEmail() {
  const devUrl = "http://localhost:3000/login"
  const prodUrl = ""
  const history = useNavigate()
  const [email, setEmail] = useState(emailInitialState);
  const auth = getAuth();
  const actionCodeSettings = {
    url: devUrl,
  };
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmail({ ...email, [name]: value });
  };

  const handleGoBack = (e) => {
    history('/login')
  }
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    console.log("email", email.email);
    await sendPasswordResetEmail(auth, email.email, actionCodeSettings)
      .then((resp) => {
        console.log("sendpasswordresetemail response", resp);
      })
      .catch((err) => {
        console.log("sendpasswordresetemail ERROR", err);
      });
  };

  return (
    <div className="bg-dashboardBackGroundGray  overflow-x-hidden">
      <div className="flex flex-row justify-start items-start">
      <button className="mt-10 ml-8" onClick={(e) => {handleGoBack(e)}}>
      <i class="fa-regular fa-arrow-left pl-2 pr-2 text-3xl text-gray-500 hover:scale-105"></i>
      </button>
      </div>
      <div className="flex flex-row items-center justify-center h-screen">
        <div className="w-1/2">
          <FormWrapper mb={"0"}>
            <h2 class="text-center mt-12 text-4xl text-indigo-800 font-display font-semibold lg:text-left xl:text-5xl xl:text-bold">
              Password reset
            </h2>
            <div className="mt-12 w-4/6 ">
              <form
                onSubmit={(e) => {
                  handlePasswordReset(e);
                }}
              >
                <div>
                  <div class="text-sm font-bold text-gray-400 tracking-wide">
                    Email address for password reset
                  </div>
                  <input
                    class="w-full text-lg py-2 mb-6 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    name="email"
                    value={email.email}
                    onChange={(e) => handleEmailChange(e)}
                    placeholder="nemo@gmail.com"
                  />
                </div>
                <div class="">
                  <button
                    class="bg-indigo-800 text-gray-100 p-4 w-2/5 mb-10 rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg"
                    type="submit"
                  >
                    SEND EMAIL
                  </button>
                </div>
              </form>
            </div>
          </FormWrapper>
        </div>
      </div>
    </div>
  );
}
