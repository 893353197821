import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import InfoIconWrapper from '../dashboard/InfoIconWrapper'
import { axiosWithAuth } from '../../utils/axiosWithAuth'
import Cookies from 'js-cookie'
export default function AuthorizationForm() {
  // const orderInfo = JSON.parse(Cookies.get('authStatus'))
  const testInfo = {
    orderID:'',
    ip:'',
    scope:'',
    fundUnits:'',
    sourceAmount:'',
    sourceAmountWithoutFees:'',
    reservation:'',
    userUnits:'',
    firebaseID:''
  }
  const history = useNavigate()
const { orderID, ip, scope, fundUnits, totalUnitsPurchased, sourceAmount, sourceAmountWithoutFees, reservation, userUnits, firebaseID} = testInfo
// console.log('orderInfo', orderInfo)
let errorMessage
let errorHeader = "Incorrect code"
const [loading, setLoading] = useState(true)
const [count, setCount] = useState(3)
errorMessage = `You have ${count} tries left.`
const [page, setPage] = useState("ALL")
const [wyreResponse, setWyreResponse] = useState(null)
const [errorState, setErrorState] = useState(true)
const [form, setForm] = useState({
  sms:'',
  card2fa:''
})
const handleChange = (e) => {
  const { name, value } = e.target

  setForm({...form, [name]:value})
}

const onSubmit = (e) => {
  e.preventDefault()
  const config = {
    sms:form.sms,
    card2fa:form.card2fa,
    scope:scope,
    reservation:reservation,
    orderID:orderID,
    firebaseID:firebaseID
  }
  setLoading(true)
  axiosWithAuth().post('/fund/wyreauthorizationcodes', config)
  .then(response => {
    console.log('response from the posting of auth codes', response.data)
    setWyreResponse({
      id:response.data.id,
      success:response.data.success
    })
    let interval;
 

  interval = setInterval(() => {

    
    axiosWithAuth().post('/fund/wyreorderstatus',{id:response.data.id}).then(res => {
      if(res.data.status === "RUNNING_CHECKS"){
          console.log('running_checks') 
      } else if (res.data.status === "PROCESSING"){
        console.log('big process boy not from poll', res.data)
         
      } else if (res.data.status === "COMPLETE"){
        clearInterval(interval)
        
        console.log("FUCK YEAH GOOD JOB", res.data)
          let data = {
            fundUnits:totalUnitsPurchased,
            managerUnits:fundUnits,
            userUnits:userUnits,
            firebaseID:firebaseID,
            orderID:res.data.id,
            ip:ip,
            amountIncludingFees:res.data.usdSourceAmount,
            sourceAmount:res.data.usdPurchaseAmount
          }
          console.log(data)
          successfulWyrePayment(data)
      } else {
        setErrorState(true)
        clearInterval(interval)
        let query = "default"
        errorHeader = "Payment error"
        errorMessage = "Couldn't process your payment, please retry again"
        setForm({
          sms:'',
          card2fa:''
        })
        setTimeout(() => {
          history(`/subscriptionpage/${query}`)
        }, 3500)
      }
    })
    .catch(err => {
      console.log(err)
    })
  }, 1500)
  })
.catch(err => {
  setCount(count - 1)
  setForm({
    sms:'',
    card2fa:''
  })
  setErrorState(true)
  errorHeader= "Incorrect code"
  errorMessage = `You have ${count} tries left.`
  console.log(err)
})
}


const successfulWyrePayment = (data) => {
  axiosWithAuth().post('/fund/successfulwyrepayment', data).then(res => {
    console.log(res.data)
    history('/subscription/completed')
  })
  .catch(err => {
    console.log(err)
    let query = "default"
    errorHeader = "Payment error"
    errorMessage = "Couldn't process your payment, please retry again"
    setErrorState(true)
    setTimeout(() => {
      history(`/subscriptionpage/${query}`)
    }, 3500)
    setForm({
      sms:'',
      card2fa:''
    })
  })
}
//useRef
// useEffect(() => {
//   let interval;
 

//   interval = setInterval(() => {
//     axiosWithAuth().post('/fund/wyreorderstatus',{id:orderID}).then(res => {
//       if(res.data.status === "RUNNING_CHECKS"){
//           console.log('not from poll') 
//       } else if (res.data.status === "PROCESSING"){
//         console.log('big process boy not from poll', res.data)
         
//       } else if (res.data.status === "COMPLETE"){
//         clearInterval(interval)
//         setCount(count + 1)
//         console.log(count)
//         console.log("FUCK YEAH GOOD JOB", res.data)
//         if(count === 1){
//           let data = {
//             fundUnits:totalUnitsPurchased,
//             managerUnits:fundUnits,
//             userUnits:userUnits,
//             firebaseID:firebaseID,
//             orderID:res.data.id,
//             ip:ip
//           }
//           successfulWyrePayment(data)
//         }
//       } else {
//         clearInterval(interval)
//         console.log(res.data)
//       }
//     })
//     .catch(err => {
//       console.log(err)
//     })
//   }, 1500)
  
// }, [wyreResponse])


const submitButtonText = "NEXT"


  return (
    <div className="flex flex-col min-h-screen overflow-auto items-center bg-dashboardBackGroundGray">
      {
        page === "loading" ?
        <div className='mt-32'>
        <i class="fa-solid fa-circle-dashed text-indigo-800 text-6xl animate-spin animate-ping "></i>
        <i class="fa-solid fa-circle-dashed text-6xl text-indigo-400 animate-ping animate-pulse"></i>
        <i class="fa-solid fa-circle-dashed text-6xl text-indigo-200 animate-spin"></i>
        <h2 className="text-4xl mt-16 text-indigo-500 font-semibold ">Loading<span className="animate-pulse">.</span><span className="animate-pulse"><span className="animate-pulse">.</span></span><span className="animate-pulse">.</span></h2>
        </div>
        :
        <></>
      }
      
    <div  className="flex flex-col w-3/4 ">
      <h2 className="text-4xl mt-16 text-indigo-500 font-semibold ">
        Step 4: Enter Authorization code
      </h2>
      {
        errorState ? 
        <div className="w-full flex items-center justify-center">
        <div className="p-4 mt-4 text-sm text-red-700 bg-red-100 w-1/2 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
  <span className="font-medium">{errorHeader}:</span> {errorMessage}
</div>
        </div>
        :
        <></>
      }
      <div className="flex items-center flex-col bg-white m-auto w-1/2 mt-10 md:w-6/7 rounded-2xl  shadow-xl rounded-sm border border-slate-200 transform duration-200 hover:scale-105 hover:shadow-2xl">
      {
        page === "ALL" ?
        <div>
        <form onSubmit={(e) => onSubmit(e)} className="">
        <div className="flex flex-row justify-between ">
          <div className="text-sm font-bold text-gray-500 mb-4 tracking-wide mt-4">
            Bank code
          </div>
        <div className="mt-4">
          <InfoIconWrapper>
            <div>Check your online bank account for an authorization code</div>
          </InfoIconWrapper>
        </div>
        </div>
          <input
          className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
          name="card2fa"
          value={form.card2fa}
          onChange={(e) => handleChange(e)}
          placeholder="000000"
          />
        <div className="flex flex-row justify-between">
      <div className="text-sm font-bold text-gray-500 mb-4 tracking-wide mt-4">
        SMS Code
      </div>
      <div></div>
      </div>
      <input
      className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
      name="sms"
      value={form.sms}
      onChange={(e) => handleChange(e)}
      placeholder="000000"
      />
        <div class="w-2/3">
                        <button class="bg-indigo-800 ml-8 text-gray-100 p-4 w-full mb-10 mt-6 rounded-full tracking-wide
                        font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                        shadow-lg" type="submit">
                            {loading ? <i class="fa-solid fa-spinner-third text-2xl text-indigo-200 animate-spin"></i> : submitButtonText}
                        </button>
                    </div>
        </form>
        </div>
      :
      <></>
    }
      {
        page === "SMS" ?
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="flex flex-row justify-between">
      <div className="text-sm font-bold text-gray-500 mb-4 tracking-wide mt-4">
        SMS Code
      </div>
      <div></div>
      </div>
      <input
      className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
      name="sms"
      value={form.sms}
      onChange={(e) => handleChange(e)}
      placeholder="000000"
      />
        <div class="w-2/3">
                        <button class="bg-indigo-800 text-gray-100 p-4 w-full mb-10 mt-6 rounded-full tracking-wide
                        font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                        shadow-lg" type="submit">
                           {loading ? <i class="fa-solid fa-spinner-third text-2xl text-indigo-200 animate-spin"></i> : submitButtonText}
                        </button>
                    </div>
        </form>
        :
        <></>
      }
      {
        page === "CARD2FA" ?
        <form onSubmit={(e) => onSubmit(e)} className="">
        <div className="flex flex-row justify-between ">
          <div className="text-sm font-bold text-gray-500 mb-4 tracking-wide mt-4">
            Bank code
          </div>
        <div className="mt-4">
          <InfoIconWrapper>
            <div>Check your online bank account for an authorization code</div>
          </InfoIconWrapper>
        </div>
        </div>
          <input
          className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
          name="card2fa"
          value={form.card2fa}
          onChange={(e) => handleChange(e)}
          placeholder="000000"
          />
        <div class="w-2/3">
                        <button class="bg-indigo-800 text-gray-100 p-4 w-full mb-10 mt-6 rounded-full tracking-wide
                        font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                        shadow-lg" type="submit">
                            {loading ? <i class="fa-solid fa-spinner-third text-2xl text-indigo-200 animate-spin"></i> : submitButtonText}
                        </button>
                    </div>
        </form>
        :
        <></>
      }
      </div>
    </div>
      </div>
  )
}
