import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { userActions } from '../../store/ducks/userDuck'
export default function SubscriptionPaymentCompletedPage() {
  const dispatch = useDispatch()
const [loading, isLoading] = useState(true)
const [spinner, setSpinner] = useState(true)
const history = useNavigate()
setTimeout(() => {
  setSpinner(false)
}, 1000)

setTimeout(() => {
  history("/userdash")
}, 3500)
  
useEffect(() => {
  dispatch(userActions.getSingleUserThunk())
}, [])

  return (
   <div class="flex justify-center bg-dashboardBackGroundGray">
     
        <div className="mt-64">
          {
            spinner ?

          <div>
          <i class="fa-solid fa-spinner-third text-6xl animate-spin"></i>
          </div>
          :
          <div>
            <i class="fa-solid fa-check text-6xl text-green-500"></i>
            <h1 className="text-6xl text-indigo-500 font-semibold">Payment Successful!</h1>
            <div>
              <p className="text-2xl font-normal">Please wait as your account information is updated.  You will be redirected to your personal dashboard.</p>
          </div>
          </div>
          }
        </div>

       
</div>
  )
}
