import React from "react";
const UserTotalUnitsSVG = (props) => (
    <svg
      width="70px"
      height="70px"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <style>{".cls-1{fill:none;}.cls-2{fill:#6366F1;}"}</style>
      </defs>
      <title />
      <g data-name="Layer 2" id="Layer_2">
        <g id="Icons">
          <g id="Award">
            <rect className="cls-1" height={60} width={60} />
            <path
              className="cls-2"
              d="M55.05,52.25l-4.81-8.32a9.75,9.75,0,0,0-3-12.91V9.9a2.34,2.34,0,0,0-2.34-2.34H11.52A2.33,2.33,0,0,0,9.19,9.9V49.75a2.33,2.33,0,0,0,2.33,2.33H28.57l-.1.17a1,1,0,0,0,0,1.06,1,1,0,0,0,1,.44l3.11-.4,1.21,2.89a1,1,0,0,0,.86.61h.06a1,1,0,0,0,.87-.5L40,48.71a10.21,10.21,0,0,0,1.77.17,10.43,10.43,0,0,0,1.78-.17L48,56.35a1,1,0,0,0,.87.5h.06a1,1,0,0,0,.86-.61L51,53.35l3.11.4a1,1,0,0,0,1-1.5ZM11.52,50.08a.33.33,0,0,1-.33-.33V9.9a.34.34,0,0,1,.33-.34H44.88a.34.34,0,0,1,.34.34V30a9.52,9.52,0,0,0-2.73-.61V13.29a1,1,0,0,0-1-1H14.91a1,1,0,0,0-1,1V46.35a1,1,0,0,0,1,1H31.3l-1.58,2.73ZM32,39.11a9.74,9.74,0,0,0,1.28,4.82l-.83,1.42H15.91V14.29H40.49V29.44A9.78,9.78,0,0,0,32,39.11Zm2.84,14.5-.72-1.73a1,1,0,0,0-1-.6l-1.86.23,3.36-5.82A9.75,9.75,0,0,0,38,48.12ZM34,39.11a7.76,7.76,0,1,1,7.76,7.77A7.77,7.77,0,0,1,34,39.11ZM50.45,51.28a1,1,0,0,0-1.05.6l-.72,1.73-3.17-5.49A9.75,9.75,0,0,0,49,45.69l3.36,5.82Z"
            />
            <path
              className="cls-2"
              d="M46.57,36.87,44.14,36l-1.59-2A1,1,0,0,0,41,34l-1.59,2L37,36.87a1,1,0,0,0-.61.63,1,1,0,0,0,.12.87l1.46,2.14L38,43.1a1,1,0,0,0,.41.78,1,1,0,0,0,.87.15l2.48-.73,2.49.73a.86.86,0,0,0,.28,0,1,1,0,0,0,.58-.19,1,1,0,0,0,.42-.78l.07-2.59,1.46-2.14a1,1,0,0,0,.13-.87A1,1,0,0,0,46.57,36.87Zm-2.78,2.76a1,1,0,0,0-.18.53l0,1.59L42,41.3a1,1,0,0,0-.56,0L40,41.75l-.05-1.59a1,1,0,0,0-.18-.53l-.89-1.32,1.49-.53a1.09,1.09,0,0,0,.46-.33l1-1.26,1,1.26a1.09,1.09,0,0,0,.46.33l1.49.53Z"
            />
            <path
              className="cls-2"
              d="M32.71,17.3h-9a1,1,0,0,0,0,2h9a1,1,0,0,0,0-2Z"
            />
            <path
              className="cls-2"
              d="M19.79,37.67H24.9a1,1,0,0,0,0-2H19.79a1,1,0,0,0,0,2Z"
            />
            <path
              className="cls-2"
              d="M29,40.09H19.79a1,1,0,0,0,0,2H29a1,1,0,0,0,0-2Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
  
  export default UserTotalUnitsSVG;