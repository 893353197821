import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate} from 'react-router-dom'
// import billingFormSchema from '../../utils/yupSchemas/billingFormSchema';
import * as yup from 'yup'
import Cookies from 'js-cookie'
import { axiosWithAuth } from '../../utils/axiosWithAuth';
import axios from 'axios'
import InfoIconWrapper from '../dashboard/InfoIconWrapper';
import CreditCardForm from './CreditCardForm';
import { wyreAccountBillingInfoStart } from '../../utils/axiosCalls';
import { userActions } from '../../store/ducks/userDuck';
import PhoneInput from 'react-phone-input-2'

let billingFormSchema = yup.object().shape({
  email:yup
  .string()
  .email()
  .required('Must enter an email'),
  phoneNumber:yup
  .string()
  .required('Must enter a phone number'),
  addressLine1:yup
  .string()
  .required('Enter your address'),
  addressLine2:yup
  .string(),
  state:yup
  .string().max(2)
  .required('Must enter your state abbreviation'),
  city:yup
  .string()
  .required('Must enter a city'),
  zipCode:yup
  .string()
  .required('Must enter your zip code'),
  dateOfBirth:yup
  .string()
  .required('Please enter a date of birth in the correct format'),
  ssn:yup
  .number().min(9)
  .required('Your Social Security is required'),
  tands:yup
  .boolean()
  .oneOf([true], 'Must Accept Terms and Conditions')
  
})

const initialErrors = {
  email:'',
  phoneNumber:'',
  addressLine1:'',
  addressLine2:'',
  state:'',
  city:'',
  zipCode:'',
  dateOfBirth:'',
  ssn:'',
  terms:false
}

export default function BillingInfoForm(onClick) {
    const user = useSelector(state => state.user.user)
    const [wyreID, setWyreID] = useState(null)
    const [page, setPage] = useState(1)
    const [errorState, setErrorState] = useState(false)
    const dispatch = useDispatch()
    const{ id} = useParams()
    const history = useNavigate()
    const initialBillingState = {
      firstName:user.firstName,
      lastName:user.lastName,
        firebaseID:user.id,
        email:user.email || "",
        phoneNumber: "",
        addressLine1:user.metaData.addressLine1 || "",
        addressLine2:user.metaData.addressLine2 || "",
        state:user.metaData.state || "",
        city:user.metaData.city || "",
        zipCode:user.metaData.zipCode || "",
        dateOfBirth:user.metaData.dateOfBirth || '',
        ssn:'',
        terms:false
      }
      const [tands, setTandS] = useState(initialBillingState.terms)
    const [form, setForm] = useState(initialBillingState);
    const [error, setError] = useState(initialErrors)
    
    const handleTermsChange = () => {
      console.log('in hanlde ts')
      setTandS(!tands)
    }

    const handlePhoneInputChange = (value, country, e) => {
      
      setForm({...form, phoneNumber: value})
      
    }

   useEffect(() => {
   
    axiosWithAuth().post('/fund/wyreaccountexists', {id:user.id})
    .then(response => {
      if(response.data.exists){
        console.log(response.data)
        Cookies.set('billinginfo', JSON.stringify(response.data.account))
        setWyreID(response.data.account.wyreID)
        setPage(2)
      }
    })
    .catch(err => console.log(err))
   }, [])


    const createTransferWyreAccount = (acc) => {
      console.log(acc)
      let config = {
        method:"POST",
        url:'http://localhost:3500/auth/wyreCreation',
        data:acc
      }
      axios.request(config)
      .then(response => {
        console.log('wyreID', response.data)
        setWyreID(response.data)
        setPage(2)
      })
      .catch(err => {
        console.log(err)
      })
    }

    

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        
        setForm({ ...form, [name]: value });
  
        yup
        .reach(billingFormSchema, name)
        .validate(value)
        .then(() => {
          setError({
            ...initialErrors,
            [name]:''
          })
        })
        .catch(err => {
          setError({
            ...initialErrors,
            [name]:err.errors
          })
        })


      };
    
      const handleBirth = (e) => {
        const { name, value } = e.target;
        const oldLength = form[name].length
        setForm({ ...form, [name]: value });
        const length = value.length
        const isLonger = oldLength < length
        const needsFormatting = length === 2 || length === 5
        if(isLonger && needsFormatting){
          
      
            setForm({...form, [name]:`${value}/`})
          
          
        }


        yup
        .reach(billingFormSchema, name)
        .validate(value)
        .then(() => {
          setError({
            initialErrors,
            [name]:''
          })
        })
        .catch(err => {
          setError({
            ...initialErrors,
            [name]:err.errors
          })
        })
      }

      const onSubmit = async (e) => {
        e.preventDefault()
        console.log(initialBillingState)
       
          
          Cookies.set('billinginfo', JSON.stringify(form))
          setPage('loading')
          createTransferWyreAccount(form)
  
      
    }


    return (
      <div className="flex flex-col min-h-screen h-screen overflow-y-scroll  items-center bg-dashboardBackGroundGray">
           {
             page === "loading" ?
             <div className='mt-32'>
             <i class="fa-solid fa-circle text-indigo-500 text-6xl animate-spin animate-ping "></i>
             <h2 className="text-4xl mt-16 text-indigo-500 font-semibold ">Loading<span className="animate-pulse">.</span><span className="animate-pulse"><span className="animate-pulse">.</span></span><span className="animate-pulse">.</span></h2>
             </div>
             :
             <></>
           }
          {
           page === 1 ?  
            <div className='w-full flex flex-col items-center justify-center'>
            <h3 className="text-4xl mt-12 text-indigo-500 font-semibold animate-fade-in-down">
                  Step 2: Enter billing information
                </h3>
        <form onSubmit={(e) => onSubmit(e)} className="flex items-center mt-10 flex-col bg-white w-1/2 md:w-6/7 rounded-2xl mb-32 shadow-xl rounded-sm border border-slate-200 transform duration-200 hover:scale-105 hover:shadow-2xl">
        <div className="w-4/5">
          <div className="text-sm font-bold text-gray-500 mt-4 tracking-wide">
            Email Address
          </div>
          <input
            className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
            name="email"
            value={form.email}
            onChange={(e) => handleChange(e)}
            placeholder="nemo@gmail.com"
            />
        </div>
        <div className="w-4/5">
          <PhoneInput
          inputClass="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
          containerClass="text-sm font-bold text-gray-500 tracking-wide mt-2"
          specialLabel="Phone Number"
          country={"us"}
          value={form.phoneNumber}
          onChange={(value, country, e) => handlePhoneInputChange(value, country, e)}
          />
        </div>
        
        <div className="mt-8 w-4/5">
          <div className="flex justify-center items-center">
            <div className="text-sm font-bold text-gray-500 tracking-wide">
              Address line 1
            </div>
          </div>
          <input
            className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
            type="address1"
            placeholder="42 Wallaby Way"
            name="addressLine1"
            value={form.addressLine1}
            onChange={(e) => handleChange(e)}
            />
        </div>
        <div className="mt-8 w-4/5">
          <div className="flex justify-center items-center">
            <div className="text-sm font-bold text-gray-500 tracking-wide">
              Address Line 2
            </div>
          </div>
          <input
            className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
            type="address2"
            placeholder="Apartment 8"
            name="addressLine2"
            value={form.addressLine2}
            onChange={(e) => handleChange(e)}
            />
        </div>
        <div className="mt-8 flex flex-row items-center justify-center w-4/5 ">
            <div className="w-1/3">
            <div className="text-sm font-bold text-gray-500 tracking-wide">
              State
            </div>
            <input
            className="w-3/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
            type="state"
            placeholder="example: FL"
            name="state"
            value={form.state}
            onChange={(e) => handleChange(e)}
            />
            </div>
            <div className="w-1/3 ml-2">
            <div className="text-sm font-bold text-gray-500 tracking-wide">
              City
            </div>
            <input
            className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
            type="city"
            placeholder="Jacksonville"
            name="city"
            value={form.city}
            onChange={(e) => handleChange(e)}
            />
            </div>
            <div className="w-1/4 ml-2">
            <div className="text-sm font-bold text-gray-500 tracking-wide">
              Zip Code
            </div>
            <input
            className="w-3/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
            type="zipCode"
            placeholder="32034"
            name="zipCode"
            value={form.zipCode}
            onChange={(e) => handleChange(e)}
            />
            </div>
        </div>
        <div className='flex flex-row mt-4 w-4/5'>

            <div className="w-1/2 ">
            <div className="text-sm font-bold text-gray-500 tracking-wide">
              Date of Birth
            </div>
            <input
            className="w-4/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
            
            placeholder="mm/dd/yyyy"
            name="dateOfBirth"
            value={form.dateOfBirth}
            onChange={(e) => handleBirth(e)}
            />
            </div>
            <div className="w-1/2 ">
            <div className="text-sm flex flex-row justify-center font-bold text-gray-500 tracking-wide">
              Social Security Number
              <div className="ml-2 flex flex-col">

              <InfoIconWrapper containerClassName={""}>
                <div>test info</div>
              </InfoIconWrapper>
              </div>
            </div>
            <input
            className="w-4/5 text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
            value={form.ssn}
            placeholder="123456789"
            name="ssn"
            onChange={(e) => {handleChange(e)}}
            
            />
            </div>
            </div>
            
            
        <div className="text-red-500">{error.email}</div>
                <div className="text-red-500">{error.phoneNumber}</div>
                <div className="text-red-500">{error.email}</div>
                <div className="text-red-500">{error.addressLine1}</div>
                <div className="text-red-500">{error.addressLine2}</div>
                <div className="text-red-500">{error.state}</div>
                <div className="text-red-500">{error.city}</div>
                <div className="text-red-500">{error.zipCode}</div>
                <div className="text-red-500">{error.dateOfBirth}</div>
                <div className="text-red-500">{error.ssn}</div>
                <div className="text-red-500">{error.tands}</div>
        <div class="w-2/3 flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
          <input
          type="checkbox"
          onChange={() => {handleTermsChange()}}
          />
          <a href="https://www.sendwyre.com/legal/user-agreement" target="_blank" className="ml-2 underline underline-offset-2">I accept Wyre's user agreement</a>
          </div>
                        <button class="bg-indigo-800 text-gray-100 p-4 w-2/5 mr-4 mb-10 mt-6 rounded-full tracking-wide
                        font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                        shadow-lg" type="submit">
                            NEXT
                        </button>
                    </div>
      </form>
                  
                </div>
                :
               <></>
                }
                {
                  page === 2 ? 
                  <div>
          
                    <CreditCardForm wyreID={wyreID} accountInfo={initialBillingState}/>
                  </div>
                  :
                  <></>
                }
                  
                          </div>
  )
              }
