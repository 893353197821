import React, {useEffect} from 'react'

import * as am5 from "@amcharts/amcharts5"
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


export default function IndividualBalanceGraph({data}){
   

    
    
    useEffect(() => {
        // let lineChart = am4core.create('userchartdiv', am4charts.XYChart)
        //     lineChart.colors.list = [
        //         am4core.color("#6367F1")
    
        //     ]
        //     lineChart.data = data
            
        //     let dateAxis = lineChart.xAxes.push(new am4charts.DateAxis())
        //     // dateAxis.title.text = "Date"
        //     dateAxis.title.fill = am4core.color("#0f172a")
        //     dateAxis.startLocation = 0.5
        //     dateAxis.endLocation = 0.5
        //     let balAxis = lineChart.yAxes.push(new am4charts.ValueAxis())
        //     // balAxis.title.text = "Daily individual Value"
        //     balAxis.title.fill = am4core.color("#0f172a")
            
        //     balAxis.fill = am4core.color("#0f172a")
        //     balAxis.fillOpacity = 1
        //     let lineSeries = lineChart.series.push(new am4charts.LineSeries())
        //     lineSeries.dataFields.valueY = 'balance'
        //     lineSeries.dataFields.dateX = 'date'
        //     lineSeries.name = 'Individual Value'
        //     console.log(lineSeries)
        //     lineSeries.fill = am4core.color("#E9F0FC")
        //     lineSeries.fillOpacity = 1
        //     lineSeries.strokeWidth = 3
        //     lineSeries.tooltipText = '${valueY.value}'
        //     lineChart.cursor = new am4charts.XYCursor()
        //     chartRef.current = lineChart
        //     return () => {
        //         lineChart.dispose()
        //     }



        const root = am5.Root.new("individualBalanceGraph")
    
    
        root.dateFormatter.setAll({
            dateFormat:"yyyy",
            dateFields:["ValueX"]
        })
        
        root.setThemes([
            am5themes_Animated.new(root)
        ])
        
        
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            focusable:true,
            panX:true,
            panY:true,
            WheelX:'panX',
            wheelY:"zoomX",
            pinchZoomX:true
        }))
        let easing = am5.ease.linear 
    
        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation:0.1,
            groupData:false,
            baseInterval:{
                timeUnit:'day',
                count:1
            },
           
            renderer:am5xy.AxisRendererX.new(root, {
    
            }),
            tooltip:am5.Tooltip.new(root, {})
        }))
    
        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation:0.2,
            renderer:am5xy.AxisRendererY.new(root, {})
    
        }))
    
        let series = chart.series.push(am5xy.LineSeries.new(root, {
            minBulletDistance:10,
            connect:false,
            xAxis:xAxis,
            yAxis:yAxis,
            valueYField:'balance',
            valueXField:"date",
            tooltip:am5.Tooltip.new(root, {
                pointerOrientation:'horizontal',
                labelText:"{valueY}"
            })
        }))
        series.set("fill", am5.color("#6367F1"))
        series.set('stroke', am5.color("#6367F1"))
        series.fills.template.setAll({
            fillOpacity:0.2,
            visible:true
        })
    
        series.strokes.template.setAll({
            strokeWidth:2
        })
    
        series.data.processor = am5.DataProcessor.new(root, {
            dateFormat:'yyyy-MM-dd',
            dateFields:["date"]
        })
        console.log('useEffect of linegraph Data', JSON.parse(data))
        series.data.setAll(JSON.parse(data))
    
    
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis:xAxis,
            behavior:'none'
        }))
    
        cursor.lineY.set("visible", false)

        chart.appear(1000, 100)
        return () => {
            root.dispose()
        }

    }, [data])

   

    

    return (
        <div id="individualBalanceGraph" className="xxxs:flex xxs:flex h-96 w-full">
        
        </div>
    )
}