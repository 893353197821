import Cookies from 'js-cookie';
import {
    login,
    register,
    getSingleUser,
    getAllInvestors,
    editExistingInvestor,
    deleteUserById,
    getUserByUsername,
    verifyToken,
    getToken,
    getSingleInvestorById,
    adminDashboardLogin,
    loginUserWithRFToken,
    loginUserWithToken,
    wyreAccountBillingInfoStart
  } from '../../utils/axiosCalls';

  import auth from '../../index'
  
  export const types = {
    GET_INVESTORS_START: "GET_INVESTORS_START",
    GET_INVESTORS_SUCCESS: "GET_INVESTORS_SUCCESS",
    GET_INVESTORS_ERROR: "GET_INVESTORS_ERROR",
    GET_INVESTORS_RESOLVE: "GET_INVESTORS_RESOLVE",
    GET_ACTIVE_USER_START: "GET_ACTIVE_USER_START",
    GET_ACTIVE_USER_SUCCESS: "GET_ACTIVE_USER_SUCCESS",
    GET_ACTIVE_USER_ERROR: "GET_ACTIVE_USER_ERROR",
    GET_ACTIVE_USER_RESOLVE: "GET_ACTIVE_USER_RESOLVE",
    GET_INVESTOR_START: "GET_INVESTOR_START",
    GET_INVESTOR_SUCCESS: "GET_INVESTOR_SUCCESS",
    GET_INVESTOR_ERROR: "GET_INVESTOR_ERROR",
    GET_INVESTOR_RESOLVE: "GET_INVESTOR_RESOLVE",
    LOGIN_START: "LOGIN_START",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_ERROR: "LOGIN_ERROR",
    LOGIN_RESOLVE: "LOGIN_RESOLVE",
    SIGNOUT_START: "SIGNOUT_START",
    SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
    SIGNOUT_ERROR: "SIGNOUT_ERROR",
    SIGNOUT_RESOLVE: "SIGNOUT_RESOLVE",
    GET_USER_START: "GET_USER_START",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_ERROR: "GET_USER_ERROR",
    GET_USER_RESOLVE: "GET_USER_RESOLVE",
    REGISTER_START: "REGISTER_START",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_ERROR: "REGISTER_ERROR",
    REGISTER_RESOLVE: "REGISTER_RESOLVE",
    EDIT_USER_START: "EDIT_USER_START",
    EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
    EDIT_USER_ERROR: "EDIT_USER_ERROR",
    EDIT_USER_RESOLVE: "EDIT_USER_RESOLVE",
    DELETE_USER_START: "DELETE_USER_START",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_USER_ERROR: "DELETE_USER_ERROR",
    DELETE_USER_RESOLVE: "DELETE_USER_RESOLVE",
    VERIFY_AUTH_TOKEN_START: "VERIFY_AUTH_TOKEN_START",
    VERIFY_AUTH_TOKEN_SUCCESS: "VERIFY_AUTH_TOKEN_SUCCESS",
    VERIFY_AUTH_TOKEN_FAILURE: "VERIFY_AUTH_TOKEN_FAILURE",
    VERIFY_AUTH_TOKEN_RESOLVE: "VERIFY_AUTH_TOKEN_RESOLVE",
    GET_AUTH_TOKEN_START: "GET_AUTH_TOKEN_START",
    GET_AUTH_TOKEN_SUCCESS: "GET_AUTH_TOKEN_SUCCESS",
    GET_AUTH_TOKEN_FAILURE: "GET_AUTH_TOKEN_FAILURE",
    GET_AUTH_TOKEN_RESOLVE: "GET_AUTH_TOKEN_RESOLVE",
    RESET_USER_STATE:'RESET_USER_STATE',
    GET_INVESTORS_START: 'GET_INVESTORS_START',
    GET_INVESTORS_SUCCESS: 'GET_INVESTORS_SUCCESS',
    GET_INVESTORS_ERROR: 'GET_INVESTORS_ERROR',
    GET_INVESTORS_RESOLVE: 'GET_INVESTORS_RESOLVE',
    VERIFY_ADMIN_START: 'VERYIFY_ADMIN_START',
    VERIFY_ADMIN_SUCCESS: 'VERYIFY_ADMIN_SUCCESS',
    VERIFY_ADMIN_ERROR: 'VERYIFY_ADMIN_ERROR',
    VERIFY_ADMIN_RESOLVE: 'VERYIFY_ADMIN_RESOLVE',
    LOGIN_WITH_TOKEN_START:"LOGIN_WITH_TOKEN_START",
    LOGIN_WITH_TOKEN_SUCCESS:"LOGIN_WITH_TOKEN_SUCCESS",
    LOGIN_WITH_TOKEN_ERROR:"LOGIN_WITH_TOKEN_ERROR",
    LOGIN_WITH_TOKEN_RESOLVED:"LOGIN_WITH_TOKEN_RESOLVED",
    CREATE_WYRE_ACCOUNT_START:"CREATE_WYRE_ACCOUNT_START",
    CREATE_WYRE_ACCOUNT_SUCCESS:"CREATE_WYRE_ACCOUNT_SUCCESS",
    CREATE_WYRE_ACCOUNT_ERROR:"CREATE_WYRE_ACCOUNT_ERROR",
    CREATE_WYRE_ACCOUNT_RESOLVED:"CREATE_WYRE_ACCOUNT_RESOLVED"
  };
  
  export const userActions = {
    loginThunk: (credentials) => (dispatch) => {
      dispatch({ type: types.LOGIN_START });
      login(credentials)
        .then((res) => {
          dispatch({ type: types.LOGIN_SUCCESS});
        })
        .catch((err) => {
          dispatch({ type: types.LOGIN_ERROR, payload: err.message });
        })
        .finally(() => {
          dispatch({ type: types.LOGIN_RESOLVE });
        });
    },

    signoutThunk: () => (dispatch) => {
       Cookies.remove("auth_token")
       Cookies.remove('rft')
      dispatch({type: types.SIGNOUT_SUCCESS})
    },
  
    registerThunk: (newUser) => (dispatch) => {
      dispatch({ type: types.REGISTER_START });
      register(newUser)
        .then((res) => {
          console.log('register res', res)
          dispatch({ type: types.REGISTER_SUCCESS });
        })
        .catch((err) => {
         
          dispatch({ type: types.REGISTER_ERROR, payload: err.message });
        })
        .finally(() => {
          dispatch({ type: types.REGISTER_RESOLVE });
        });
    },

    verifyAdminThunk: (credentials) => (dispatch) => {
      dispatch({ type: types.VERIFY_ADMIN_START })
      adminDashboardLogin(credentials)
      .then((res) => {
          if(res.data.verified){
            dispatch({ type: types.VERIFY_ADMIN_SUCCESS, payload: true })
          } else {
            dispatch({ type: types.VERIFY_ADMIN_SUCCESS, payload: false })
          }
      })
      .catch((err) => {
          dispatch({ type: types.VERIFY_ADMIN_ERROR, payload: err.message })
      })
    },
  
    getAllInvestorsThunk: () => (dispatch) => {
      dispatch({ type: types.GET_INVESTORS_START });
      getAllInvestors()
        .then((res) => {
          dispatch({ type: types.GET_INVESTORS_SUCCESS, payload: res.data });
        })
        .catch((err) => {
          dispatch({ type: types.GET_INVESTORS_ERROR, payload: err.message });
        })
        .finally(() => {
          dispatch({ type: types.GET_INVESTORS_RESOLVE });
        });
    },
  
    getSingleUserThunk: () => (dispatch) => {
      dispatch({ type: types.GET_USER_START });
      getSingleUser()
        .then((res) => {
          dispatch({ type: types.GET_USER_SUCCESS, payload: res.data });
        })
        .catch((err) => {
          dispatch({ type: types.GET_USER_ERROR, payload: err.message });
        })
        .finally(() => {
          dispatch({ type: types.GET_USER_RESOLVE });
        });
    },

    getInvestorThunk: (id) => (dispatch) => {
      dispatch({ type: types.GET_INVESTOR_START });
      getSingleInvestorById(id)
        .then((res) => {
          dispatch({ type: types.GET_INVESTOR_SUCCESS, payload: res.data });
        })
        .catch((err) => {
          dispatch({ type: types.GET_INVESTOR_ERROR, payload: err.message });
        })
        .finally(() => {
          dispatch({ type: types.GET_INVESTOR_RESOLVE });
        })
    },

    getUserByUsernameThunk: (username) => (dispatch) => {
      dispatch({ type: types.GET_ACTIVE_USER_START });
      getUserByUsername(username)
        .then((res) => {
          dispatch({ type: types.GET_ACTIVE_USER_SUCCESS, payload: res.data });
        })
        .catch((err) => {
          dispatch({ type: types.GET_ACTIVE_USER_ERROR, payload: err.message });
        })
        .finally(() => {
          dispatch({ type: types.GET_ACTIVE_USER_RESOLVE });
        });
    },
  
    editUserThunk: (editedUser) => (dispatch) => {
      dispatch({ type: types.EDIT_USER_START });
      editExistingInvestor(editedUser)
        .then((res) => {
          dispatch({ type: types.EDIT_USER_SUCCESS, payload: res.data });
        })
        .catch((err) => {
          dispatch({ type: types.EDIT_USER_ERROR, payload: err.message });
        })
        .finally(() => {
          dispatch({ type: types.EDIT_USER_RESOLVE });
        });
    },
  
    deleteUserThunk: (userId) => (dispatch) => {
      dispatch({ type: types.DELETE_USER_START });
      deleteUserById(userId)
        .then((res) => {
          dispatch({ type: types.DELETE_USER_SUCCESS });
        })
        .catch((err) => {
          dispatch({ type: types.DELETE_USER_ERROR, payload: err.message });
        })
        .finally(() => {
          dispatch({ type: types.DELETE_USER_RESOLVE });
        });
    },

    verifyAuthToken: () => (dispatch) => {
      dispatch({type: types.VERIFY_AUTH_TOKEN_START });
      const token = Cookies.get("auth_token")
      verifyToken(token)
        .then((res) => {
          dispatch({type: types.VERIFY_AUTH_TOKEN_SUCCESS})
        })
        .catch((err) => {
          dispatch({type: types.VERIFY_AUTH_TOKEN_FAILURE})
        })
        .finally(() => {
          dispatch({type: types.VERIFY_AUTH_TOKEN_RESOLVE})
        })
    },

    getAuthToken: () => (dispatch) => {
      dispatch({type: types.GET_AUTH_TOKEN_START});
      getToken()
        .then((res) => {
          dispatch({type: types.GET_AUTH_TOKEN_SUCCESS});
          Cookies.set("auth_token", res, { expires: 365 });
        })
        .catch((err) => {
          dispatch({type: types.GET_AUTH_TOKEN_FAILURE});
        })
        .finally(() => {
          dispatch({type: types.GET_AUTH_TOKEN_RESOLVE});
        })
    },
    resetUserStateThunk: () => (dispatch) => {
      dispatch({type:types.RESET_USER_STATE})
      console.log('here', userInitialState)
    },
    loginWithTokenThunk: (token) => (dispatch) => {
      dispatch({type:types.LOGIN_WITH_TOKEN_START})
      loginUserWithRFToken(token)
      .then(res => {
        const token = res
        Cookies.set('auth_token', token, { expires: 365 })
        //add types so we can dispatch in here instead of chaining .then
        loginUserWithToken(token)
        .then(res => {
          dispatch({type:types.LOGIN_WITH_TOKEN_SUCCESS, payload:res})
        })
      })
      .catch(res => {

      })
    },
    createWyreTransferAccountThunk: (accInfo) => (dispatch) => {
      dispatch({type:types.CREATE_WYRE_ACCOUNT_START})
      wyreAccountBillingInfoStart(accInfo)
      .then(res => {
        console.log('resresres', res)
        dispatch({type:types.CREATE_WYRE_ACCOUNT_START, payload:res})
      })
      .catch(err => {
        console.log(err)
      })
    }
  };
  
  const userInitialState = {
    user: {},
    investors: [],
    activeUser: {},
    investor: {},
    investors:[],
    status: "idle",
    error: "",
    loggedIn: false,
    isVerified: false,
    isLoading: false,
    isAdminVerified: false,
    wyreBillingInfo:{}
  };

  
  const userReducer = (state = userInitialState, action) => {
    switch (action.type) {
      case types.LOGIN_START:
        return {
          ...state,
          status: "post/pending",
          isLoading: true
        };
      case types.LOGIN_SUCCESS:
        return {
          ...state,
          isVerified: true,
          status: "post/success",
          loggedIn: true,
          isLoading: false,
        };
      case types.LOGIN_ERROR:
        return {
          ...state,
          error: action.payload,
          status: "post/error",
          loggedIn:false,
          isLoading: false,
          isVerified: false,
        };
      case types.LOGIN_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.SIGNOUT_SUCCESS:
        return {
          ...state,
          user: {},
          loggedIn: false,
          isVerified: false,
        }
      case types.REGISTER_START:
        return {
          ...state,
          status: "post/pending",
          isLoading: true
        };
      case types.REGISTER_SUCCESS:
        return {
          ...state,
          status: "post/success",
          loggedIn: true,
          isVerified: true,
          isLoading: false
        };
      case types.REGISTER_ERROR:
        return {
          ...state,
          error: action.payload,
          status: "post/error",
          // isLoading: false
        };
      case types.REGISTER_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.VERIFY_ADMIN_START:
        return {
          ...state,
          status: "post/start"
        };
      case types.VERIFY_ADMIN_SUCCESS:
        return {
          ...state,
          status: "post/success",
          isAdminVerified: action.payload
        };
      case types.VERIFY_ADMIN_ERROR:
        return {
          ...state,
          status: "post/error",
          error: action.payload
        };
      case types.GET_INVESTORS_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_INVESTORS_SUCCESS:
        return {
          ...state,
          investors: action.payload,
          status: "get/success",
        };
      case types.GET_INVESTORS_ERROR:
        return {
          ...state,
          error: action.payload,
          status: "get/error",
        };
      case types.GET_INVESTORS_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.GET_USER_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_USER_SUCCESS:
        return {
          ...state,
          user: action.payload,
          status: "get/success",
        };
      case types.GET_USER_ERROR:
        return {
          ...state,
          error: action.payload,
          status: "get/error",
        };
      case types.GET_USER_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.GET_INVESTOR_START:
        return {
          ...state,
          status: "get/pending"
        }
      case types.GET_INVESTOR_SUCCESS:
        return {
          ...state,
          investor: action.payload,
          status: "get/success"
        }
      case types.GET_INVESTOR_ERROR:
        return {
          ...state,
          error: action.payload,
          status: "get/error"
        }
      case types.GET_INVESTOR_RESOLVE:
        return {
          ...state,
          status: "idle"
        }
      case types.GET_ACTIVE_USER_START:
        return {
          ...state,
          status: "get/pending",
        };
      case types.GET_ACTIVE_USER_SUCCESS:
        return {
          ...state,
          activeUser: action.payload,
          status: "get/success",
        };
      case types.GET_ACTIVE_USER_ERROR:
        return {
          ...state,
          error: action.payload,
          status: "get/error",
        };
      case types.GET_ACTIVE_USER_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.EDIT_USER_START:
        return {
          ...state,
          status: "edit/pending",
        };
      case types.EDIT_USER_SUCCESS:
        return {
          ...state,
          status: "edit/success",
          user: action.payload.data
        };
      case types.EDIT_USER_ERROR:
        return {
          ...state,
          error: action.payload,
          status: "edit/error",
        };
      case types.EDIT_USER_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
      case types.DELETE_USER_START:
        return {
          ...state,
          status: "delete/pending",
        };
      case types.DELETE_USER_SUCCESS:
        return {
          ...state,
          status: "delete/success",
        };
      case types.DELETE_USER_ERROR:
        return {
          ...state,
          error: action.payload,
          status: "delete/error",
        };
      case types.DELETE_USER_RESOLVE:
        return {
          ...state,
          status: "idle",
        };
        case types.GET_INVESTORS_START:
          return {
            ...state,
            status: "get/pending",
          };
        case types.GET_INVESTORS_SUCCESS:
          return {
            ...state,
            investors: action.payload,
            status: "get/success",
          };
        case types.GET_INVESTORS_ERROR:
          return {
            ...state,
            error: action.payload,
            status: "get/error",
          };
        case types.GET_INVESTORS_RESOLVE:
          return {
            ...state,
            status: "idle",
          };
      case types.VERIFY_AUTH_TOKEN_START:
        return {
          ...state,
          status: "get/start"
        }
      case types.VERIFY_AUTH_TOKEN_SUCCESS:
        return {
          ...state,
          isVerified: true,
          status: "get/start"
        }
      case types.VERIFY_AUTH_TOKEN_FAILURE:
        return {
          ...state,
          isVerified: false,
          status: "get/start"
        }
      case types.GET_AUTH_TOKEN_START:
        return {
          ...state,
          status: "get/start"
        }
      case types.GET_AUTH_TOKEN_SUCCESS:
        return {
          ...state,
          status: "get/success",
          isVerified: true
        }
      case types.GET_AUTH_TOKEN_FAILURE:
        return {
          ...state,
          status: "get/failure",
          isVerified: false
        }
      case types.GET_AUTH_TOKEN_RESOLVE:
        return {
          ...state,
          status: "idle"
        }
      case types.RESET_USER_STATE:
        return userInitialState

      case types.LOGIN_WITH_TOKEN_SUCCESS:
        return {
          ...state,
          user: action.payload,
    loggedIn: true,
    isVerified: true,
    isAdminVerified: false
        }
      case types.CREATE_WYRE_ACCOUNT_START:
        return {
          ...state,
          status:'get/start'
        }
      case types.CREATE_WYRE_ACCOUNT_SUCCESS:
        return {
          ...state,
          wyreBillingInfo:{
           wyreID:action.payload
          },
          status:'get/success'
        }
      default:
        return state;
    }
  };
  
  export default userReducer;