import * as yup from 'yup'

export default yup.object().shape({
    firstName:yup
    .string()
    .required(),
    lastName:yup
    .string()
    .required('Last name is required'),
    email:yup
    .string()
    .email('Must enter an email')
    .required('Must be a valid email'),
    phoneNumber:yup
    .string()
    .required("must enter a phone number"),
    password:yup
    .string()
    .required()
    .min(8, 'Password must be at least 8 characters'),
    confirmPassword:yup
    .string()
    .required()
    .min(8, 'Passwords must match'),
    addressLine1:yup
    .string()
    .required('Enter your address'),
    addressLine2:yup
    .string(),
    state:yup
    .string()
    .required('Must enter your state abbreviation'),
    city:yup
    .string()
    .required('Must enter a city'),
    zipCode:yup
    .string()
    .required('Must enter a zip code')
})