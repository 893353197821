import React from "react";

const TempMobileView = ({ data, labels }) => {
    return (
        <div className="overflow-x-auto mt-4 mx-0 w-full">
          {Object.entries(data).map(([month, values]) => (
            <div className="flex flex-col my-8 mb-0 border rounded-lg p-4 border-b rounded-none p-2 odd:bg-gray-100 w-full">
              <div className="text-indigo-500 mb-4">
                <strong>{month}</strong>
              </div>
              {Object.entries(values).map(([key, value]) => (
                <div key={key} className="flex justify-between">
                  <strong className="text-indigo-500">{labels[key]}</strong>
                  <div>{value}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
};

export default TempMobileView;