import React, {useEffect, useRef} from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";


export default function CoinAllotmentPieGraph({data}) {
  const chartRef = useRef(null)

  useEffect(() => {
    let pieChart = am4core.create('piechartdiv', am4charts.PieChart)
        
    let pieSeries = pieChart.series.push(new am4charts.PieSeries())
    pieChart.data = data
    

    pieSeries.dataFields.value = "coinAmount"
    pieSeries.dataFields.category = 'coinName'
    pieSeries.slices.template.tooltipText = `{category}\n{value.value}${data[0].symbol}`
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.labels.template.fill = am4core.color("#1F2937")
    pieSeries.ticks.template.stroke = new am4core.color("#1F2937")
    pieSeries.ticks.template.fillOpacity = 2
    pieSeries.ticks.template.strokeWidth = 1.2
    pieSeries.ticks.template.strokeOpacity = 1
    pieSeries.ticks.template.fill = new am4core.color("#1F2937")

    pieSeries.colors.list = [
        am4core.color("#7C3AED"),
        am4core.color("#371F1F"),
        am4core.color("#F7931A"),
        am4core.color("#002046"),
        am4core.color("#4B5563")
      ];
      chartRef.current = pieChart
      return () => {
        pieChart.dispose()
      }
  }, [])
    useEffect(() => {
      if(chartRef.current){
        chartRef.current.data = data
      }

    }, [data])
  
  
  
  
  
    return (
    <div id="piechartdiv" className="w-full h-full overflow-visible piechartdiv">

    </div>
  )
}
