import { combineReducers, createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";

import userReducer from "./ducks/userDuck";
import cryptoReducer from './ducks/cryptoDucks'

export const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
}

export const createStoreWithMiddleware = applyMiddleware(...middleware)(
  createStore
);

export const rootReducer = combineReducers({
  user: userReducer,
  crypto:cryptoReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStoreWithMiddleware(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store)

export default store;