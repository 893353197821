import React, { useEffect, useState, useMemo, useRef } from "react";
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
export default function FundBalanceGraph({ data }) {
  const [chart, setChart] = useState(undefined);
  const chartRef = useRef(null);

 
  useEffect(() => {
    const root = am5.Root.new("fundBalanceGraph")
    
    
    root.dateFormatter.setAll({
        dateFormat:"yyyy",
        dateFields:["ValueX"]
    })
    
    root.setThemes([
        am5themes_Animated.new(root)
    ])
    
    
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
        focusable:true,
        panX:true,
        panY:true,
        WheelX:'panX',
        wheelY:"zoomX",
        pinchZoomX:true
    }))
    let easing = am5.ease.linear 

    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
        maxDeviation:0.1,
        groupData:false,
        baseInterval:{
            timeUnit:'day',
            count:1
        },
       
        renderer:am5xy.AxisRendererX.new(root, {

        }),
        tooltip:am5.Tooltip.new(root, {})
    }))

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation:0.2,
        renderer:am5xy.AxisRendererY.new(root, {})

    }))

    let series = chart.series.push(am5xy.LineSeries.new(root, {
        minBulletDistance:10,
        connect:false,
        xAxis:xAxis,
        yAxis:yAxis,
        valueYField:'balance',
        valueXField:"date",
        tooltip:am5.Tooltip.new(root, {
            pointerOrientation:'horizontal',
            labelText:"{valueY}"
        })
    }))
    series.set("fill", am5.color("#6367F1"))
    series.set('stroke', am5.color("#6367F1"))
    series.fills.template.setAll({
        fillOpacity:0.2,
        visible:true
    })

    series.strokes.template.setAll({
        strokeWidth:2
    })

    series.data.processor = am5.DataProcessor.new(root, {
        dateFormat:'yyyy-MM-dd',
        dateFields:["date"]
    })
    console.log('useEffect of linegraph Data', JSON.parse(data))
    series.data.setAll(JSON.parse(data))


    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        xAxis:xAxis,
        behavior:'none'
    }))

    cursor.lineY.set("visible", false)

    



    chart.appear(1000, 100)
    return () => {
        root.dispose()
    }
    
  }, [data]);

  useEffect(() => {
    if (chartRef.current) {
      
    }
  }, [data]);

  return <div id="fundBalanceGraph" className="h-96 w-full"></div>;
}
