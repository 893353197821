import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux'
import {store, persistor} from './store'
import {BrowserRouter} from 'react-router-dom'
import { PersistGate } from 'redux-persist/lib/integration/react'

import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import "firebase/firestore";

const fb_config = {
    apiKey:"AIzaSyBTQs1hjOtrL9psToi9coob_v9fGb1fiy8",
    authDomain: "tothemoon-mining.firebaseapp.com",
    projectId: "tothemoon-mining",
    storageBucket: "tothemoon-mining.appspot.com",
    messagingSenderId: "498313185684",
    appId: "1:498313185684:web:5d9fb99c78769002e52781",
    measurementId:"G-Y9W4TBDCP5"
}

const app = initializeApp(fb_config)
const auth = getAuth()
// auth.setPersistence(auth.auth.Auth.Persistence.LOCAL)
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

export default auth

