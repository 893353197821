import React from 'react'

export default function LGLoginSVG(props) {
  return (
    
    <svg
    id="e4da07c6-3f45-486e-a553-879e7f222c67"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={624}
    height={771}
    viewBox="0 0 926 749"
    {...props}
    >
    <title>{"login"}</title>
    <circle cx={237.90496} cy={226.93548} r={83.97916} fill="#ff6584" />
    <path
      d="M1063,276.46V497.34a325.99128,325.99128,0,0,1-326,326H137V602.46c0-118.03,62.73-221.42,156.68-278.63a323.57742,323.57742,0,0,1,50.88-25.18,320.09024,320.09024,0,0,1,31.76-10.53c.21-.07.43-.13.64-.18q4.77-1.305,9.6-2.46c.18-.05.35-.09.53-.13a326.11582,326.11582,0,0,1,60.47-8.53c2.41-.11005,4.82-.2,7.25-.25.87-.03,1.75-.05,2.64-.05q2.76-.06,5.55-.06Z"
      transform="translate(-137 -75.5)"
      fill="#3f3d56"
      />
    <path
      d="M569,681c-238.0863,0-431.181,64.20129-431.99048,143.5h863.981C1000.18091,745.20129,807.0863,681,569,681Z"
      transform="translate(-137 -75.5)"
      fill="#f2f2f2"
      />
    <g opacity={0.1}>
      <ellipse cx={352} cy={692} rx={80} ry={22.22222} />
    </g>
    <g opacity={0.1}>
      <ellipse cx={525} cy={652} rx={80} ry={22.22222} />
    </g>
    <g opacity={0.1}>
      <ellipse cx={352} cy={692} rx={72} ry={20} />
    </g>
    <g opacity={0.1}>
      <ellipse cx={525} cy={652} rx={72} ry={20} />
    </g>
    <circle cx={138} cy={290} r={7} fill="#f2f2f2" />
    <circle cx={740} cy={564} r={7} fill="#f2f2f2" />
    <circle cx={410} cy={339} r={7} fill="#f2f2f2" />
    <circle cx={757} cy={297} r={4} fill="#ff6584" />
    <circle cx={154} cy={497} r={4} fill="#ff6584" />
    <polygon
      points="94 581.322 92.178 581.322 92.178 579.5 91.822 579.5 91.822 581.322 90 581.322 90 581.678 91.822 581.678 91.822 583.5 92.178 583.5 92.178 581.678 94 581.678 94 581.322"
      fill="#f2f2f2"
      />
    <polygon
      points="679 433.322 677.178 433.322 677.178 431.5 676.822 431.5 676.822 433.322 675 433.322 675 433.678 676.822 433.678 676.822 435.5 677.178 435.5 677.178 433.678 679 433.678 679 433.322"
      fill="#f2f2f2"
      />
    <polygon
      points="830 403.322 828.178 403.322 828.178 401.5 827.822 401.5 827.822 403.322 826 403.322 826 403.678 827.822 403.678 827.822 405.5 828.178 405.5 828.178 403.678 830 403.678 830 403.322"
      fill="#f2f2f2"
      />
    <polygon
      points="505 483.322 503.178 483.322 503.178 481.5 502.822 481.5 502.822 483.322 501 483.322 501 483.678 502.822 483.678 502.822 485.5 503.178 485.5 503.178 483.678 505 483.678 505 483.322"
      fill="#f2f2f2"
      />
    <polygon
      points="786 493.322 784.178 493.322 784.178 491.5 783.822 491.5 783.822 493.322 782 493.322 782 493.678 783.822 493.678 783.822 495.5 784.178 495.5 784.178 493.678 786 493.678 786 493.322"
      fill="#f2f2f2"
      />
    <polygon
      points="447 536.322 445.178 536.322 445.178 534.5 444.822 534.5 444.822 536.322 443 536.322 443 536.678 444.822 536.678 444.822 538.5 445.178 538.5 445.178 536.678 447 536.678 447 536.322"
      fill="#f2f2f2"
      />
    <polygon
      points="575 538.322 573.178 538.322 573.178 536.5 572.822 536.5 572.822 538.322 571 538.322 571 538.678 572.822 538.678 572.822 540.5 573.178 540.5 573.178 538.678 575 538.678 575 538.322"
      fill="#f2f2f2"
      />
    <polygon
      points="96 368.322 94.178 368.322 94.178 366.5 93.822 366.5 93.822 368.322 92 368.322 92 368.678 93.822 368.678 93.822 370.5 94.178 370.5 94.178 368.678 96 368.678 96 368.322"
      fill="#f2f2f2"
      />
    <rect
      x={676.99708}
      y={230.68917}
      width={2.87443}
      height={58.06342}
      fill="#e6e6e6"
      />
    <polygon
      points="611.748 385.046 585.303 445.984 590.477 454.607 588.752 457.481 600.825 463.805 600.825 460.356 605.424 459.206 624.97 423.563 611.748 385.046"
      fill="#e6e6e6"
      />
    <path
      d="M728.05172,532.40654s-19.5461,30.46892,2.29954,35.068,4.0242-32.19358,4.0242-32.19358Z"
      transform="translate(-137 -75.5)"
      fill="#e6e6e6"
      />
    <path
      d="M756.2211,518.60929l-6.89862,22.42053-2.87443,28.74426-5.174,10.34794,5.74885,46.56571,9.19816,9.19816,4.0242,45.99083v20.69587l10.92282,18.97121-14.06578,19.96434a6.36092,6.36092,0,0,0,4.99044,10.03088c3.10539.08155,7.19907-.79588,12.52465-3.55049,16.67168-8.62328,16.09679-16.67168,16.09679-16.67168s10.34794,0,10.92282-8.04839-6.32374-65.53693-6.32374-65.53693l-6.32373-24.72006V565.74988h2.29954l1.14977,57.48854,5.174,21.27075.57488,9.19817,7.47351,91.98165s33.34335,9.19816,35.068,1.72465-12.64747-12.07259-12.64747-12.07259l8.62328-16.67167V686.4758l-2.87443-6.89862-5.174-46.56571,8.62328-9.19817,4.59908-5.74885V572.07362l-5.74885-17.82144-1.72465-14.947-9.19817-22.99541S783.24071,502.5125,756.2211,518.60929Z"
      transform="translate(-137 -75.5)"
      fill="#fff"
      />
    <path
      d="M830.95619,388.68521a43.69128,43.69128,0,1,1-78.75928-26.05956l.01151-.01151a43.6871,43.6871,0,0,1,78.74777,26.07107Z"
      transform="translate(-137 -75.5)"
      fill="#6c63ff"
      />
    <g opacity={0.1}>
      <path
        d="M824.7474,370.57057l-3.56426,20.41418c-26.784-69.52663-69.38863-26.81841-70.1245-26.07107a43.69088,43.69088,0,0,1,73.68876,5.65689Z"
        transform="translate(-137 -75.5)"
        />
    </g>
    <path
      d="M825.89717,368.271l-3.56426,20.41418c-26.784-69.52663-69.38862-26.81841-70.12449-26.07107a43.69087,43.69087,0,0,1,73.68875,5.65689Z"
      transform="translate(-137 -75.5)"
      fill="#fff"
      />
    <path
      d="M819.90787,359.65262l5.9893,20.79027s-12.4326,37.26348-70.086,38.56225l-1.88957,5.323-6.32374,1.72466-2.87442,12.07259,8.04839,76.45974s-3.44931,7.47351,2.29954,6.89863c0,0,37.94243-12.64748,70.7109-1.14977,0,0,6.89862,2.87442,5.174-3.44932,0,0,22.99542-14.37213,21.27076-29.31915s1.72466-84.50814,1.72466-84.50814,3.44931-20.121-6.89863-24.72006C847.053,378.33728,831.855,357.06475,819.90787,359.65262Z"
      transform="translate(-137 -75.5)"
      fill="#fff"
      />
    <path
      d="M807.30443,504.91751s-36.01836,3.61713-26.04794,23.59148,27.725-16.85061,27.725-16.85061Z"
      transform="translate(-137 -75.5)"
      fill="#e6e6e6"
      />
    <path
      d="M825.78223,415.70482s-6.32374,7.47351-4.0242,19.5461,10.92282,39.0922,10.92282,39.0922L802.21193,502.5125,811.41009,515.16l41.39175-21.27076L862,488.14037v0A238.50666,238.50666,0,0,0,845.10813,420.5l-1.50446-3.64543S832.68085,412.25551,825.78223,415.70482Z"
      transform="translate(-137 -75.5)"
      fill="#e6e6e6"
      />
    <circle cx={685.9078} cy={309.16101} r={10.63538} fill="#e6e6e6" />
    <circle cx={678.43429} cy={230.40173} r={7.76095} fill="#e6e6e6" />
    <polygon
      points="481 352.322 479.178 352.322 479.178 350.5 478.822 350.5 478.822 352.322 477 352.322 477 352.678 478.822 352.678 478.822 354.5 479.178 354.5 479.178 352.678 481 352.678 481 352.322"
      fill="#f2f2f2"
      />
    <polygon
      points="645 296.322 643.178 296.322 643.178 294.5 642.822 294.5 642.822 296.322 641 296.322 641 296.678 642.822 296.678 642.822 298.5 643.178 298.5 643.178 296.678 645 296.678 645 296.322"
      fill="#f2f2f2"
      />
    <polygon
      points="627 313.322 625.178 313.322 625.178 311.5 624.822 311.5 624.822 313.322 623 313.322 623 313.678 624.822 313.678 624.822 315.5 625.178 315.5 625.178 313.678 627 313.678 627 313.322"
      fill="#f2f2f2"
      />
    <circle cx={556} cy={267} r={4} fill="#f2f2f2" />
    <path
      d="M495.9578,370.6945c0,279.044-35.08846,279.044-78.35963,279.044-22.37132,0-42.55355,0-56.82569-38.5458-4.10333-11.078-7.72825-25.36221-10.767-43.7498-6.843-41.3332-10.767-103.44665-10.767-196.74843,0-35.87794,4.247-71.05008,10.767-103.90133,3.13438-15.81545,6.79508-31.08064,10.767-45.64,4.85717-17.82535,10.18078-34.58595,15.55229-49.92291,3.601-10.31236,7.23786-19.99063,10.767-28.92726,16.306-41.24944,30.50643-66.803,30.50643-66.803S495.9578,216.58322,495.9578,370.6945Z"
      transform="translate(-137 -75.5)"
      fill="#f2f2f2"
      />
    <g opacity={0.1}>
      <path
        d="M387.09174,142.303V628.80275h-10.767V171.2303C379.92574,160.91794,383.56263,151.23967,387.09174,142.303Z"
        transform="translate(-137 -75.5)"
        />
    </g>
    <circle cx={278.2055} cy={192.60917} r={43.06789} fill="#3f3d56" />
    <path
      d="M509.71558,623.41925c-9.57593-23.57172-21.87134-27.63849-29.90821-27.28485v-3.81976H354.19263v3.81976c-8.03687-.35364-20.33228,3.71313-29.90821,27.28485C308.73218,661.70184,333.855,727.5,333.855,727.5s-13.42615-91.767,31.15833-92.371A52.76238,52.76238,0,0,0,379,647.8186V672.5h72V650.06592a52.707,52.707,0,0,0,17.98669-14.937C513.57117,635.733,500.145,727.5,500.145,727.5S525.26782,661.70184,509.71558,623.41925Z"
      transform="translate(-137 -75.5)"
      fill="#6c63ff"
      //fill="#6c63ff" <==== the original blue thing
      //#C86567
      />
    <circle cx={278.2055} cy={192.60917} r={33.49725} fill="#6c63ff" />
    <path
      d="M983.01,276.46h-2A82.08452,82.08452,0,0,0,1063,358v-2A80.07862,80.07862,0,0,1,983.01,276.46Z"
      transform="translate(-137 -75.5)"
      fill="#6c63ff"
      />
    <path
      d="M946.01,276.46h-2A119.11543,119.11543,0,0,0,1063,395v-2A117.12247,117.12247,0,0,1,946.01,276.46Z"
      transform="translate(-137 -75.5)"
      fill="#6c63ff"
      />
    <path
      d="M908,276.46h-2C906.25,362.82,976.58,433,1063,433v-2C977.68,431,908.25,361.72,908,276.46Z"
      transform="translate(-137 -75.5)"
      fill="#6c63ff"
      />
  </svg>
  )
}
