import React from 'react'
import InfoIconWrapper from './InfoIconWrapper'
import UserTotalUnitsSVG from '../../assets/SVGs/UserTotalUnitsSVG'
{/* <div className="flex flex-col w-1/6 bg-white shadow-lg rounded-sm border border-slate-200 mr-4 py-2 transform duration-200 hover:scale-110 hover:shadow-xl">
        <div className="px-2 border-gray-100 flex justify-between ">
          <div className="flex flex-row items-center ">
        <h2 className="text-lg font-bold text-gray-800 ml-4">{title}</h2>
          </div>
        <InfoIconWrapper containerClassName="min-w-44">
        <div className="text-sm text-center">{tooltipText}</div>
        </InfoIconWrapper>
        </div>
        <div className="flex items-start justify-center">
            <div className=" text-3xl font-bold text-gray-900 mr-2 ">{info}</div>
        <dl></dl>
        </div>
    </div> */}
export default function UserInfoCard({title, info, tooltipText}) {



  return (
    <div className="flex flex-col bg-white xxxs:mb-4 xxs:w-5/6  xs:w-3/5 sm:mb-4 sm:w-1/2 md:mb-2 md:w-2/5 lg:w-1/5 xl:w-1/5 2xl:w-1/6 shadow-lg rounded-2xl border border-slate-200 mr-4 py-2 transform duration-200 hover:scale-110 hover:shadow-xl">
    <div className="px-2 border-gray-100 flex justify-between ">
      <div className="flex flex-row items-center ">
    <h2 className="text-sm font-medium text-gray-500 ml-4">{title}</h2>
      </div>
    <InfoIconWrapper containerClassName="min-w-44">
    <div className="text-sm text-center">{tooltipText}</div>
    </InfoIconWrapper>
    </div>
    <div className="flex items-start justify-start ml-2">
        <div className=" text-5xl font-light text-gray-900 mr-2 ">{info}</div>
    <dl></dl>
    </div>
</div>
  )
}

    