import React, {useState} from 'react'
import TransitionTemplate from '../../utils/transition/TransitionTemplate'

export default function InfoIconWrapper({containerClassName, children}) {
const [infoOpen, setInfoOpen] = useState(false)



  return (
    <div 
    className="block top-0 z-60"
    onMouseEnter={() => setInfoOpen(true)}
    onMouseLeave={() => setInfoOpen(false)}
    onFocus={() => setInfoOpen(true)}
    onBlur={() => setInfoOpen(false)}
    >
    <button
    aria-haspopup="true"
    aria-expanded={infoOpen}
    onClick={() => setInfoOpen(true)}
    >
<svg className="w-4 h-4 fill-current z-60 text-gray-400" viewBox="0 0 16 16">
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
        </svg>
    </button>
    <div className="z-10 absolute bottom-full transform -translate-x-1/2">
      <TransitionTemplate
      show={infoOpen}
      tag="div"
      className={`bg-white border border-slate-200 p-3 rounded shadow-lg overflow-hidden  ${containerClassName}`}
      enter="transition ease-out duration-200 transform"
      enterStart="opacity-0 -translate-y-2"
      enterEnd="opacity-100 translate-y-0"
      leave="transition ease-out duration-200"
      leaveStart="opacity-100"
      leaveEnd="opacity-0"
      >
          
            {children}
        </TransitionTemplate>
    </div>
    </div>
  )
}
