import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux";
import { Link, useNavigate } from 'react-router-dom'

import { userActions } from '../../store/ducks/userDuck';
import { cryptoActions } from '../../store/ducks/cryptoDucks';
import { axiosWithAuth} from '../../utils/axiosWithAuth'
import SideBar from '../../partials/navbar/SideBar'
import Header from '../../partials/navbar/Header';
import LineGraphWrapper from '../../partials/dashboard/LineGraphWrapper';
import UserInfoCard from '../../partials/dashboard/UserInfoCard';
import UserTotalUnitsSVG from '../../assets/SVGs/UserTotalUnitsSVG';
import InitialInvestmentSVG from '../../assets/SVGs/InitialInvestmentSVG'
import FundPercentageSVG from '../../assets/SVGs/FundPercentageSVG'

var HashMap = require('hashmap')




export default function UserDash() {
  const dispatch = useDispatch()
  const [showSideBar, setShowSideBar] = useState(false)
  const [dailyFundValue, setDailyFundValue] = useState(null)
  const [lineGraphStateKey, setLineGraphStateKey] = useState("all_time_data");
  const [isUserData, setIsUserData] = useState(false)
  const [currentFundValue, setCurrentFundValue] = useState(0)
  const user = useSelector(state => state.user.user)
  console.log('user', user)
  const history = useNavigate()
 


  const bitcoinUSDValue = useSelector(state => state.crypto.btcUSDPrice)
  const ethereumUSDValue = useSelector(state => state.crypto.ethUSDPrice)
  const cakeUSDValue = useSelector(state => state.crypto.cakeUSDPrice)
  const bnbUSDValue = useSelector(state => state.crypto.bnbUSDPrice)
  const polyUSDValue = useSelector(state => state.crypto.polyUSDPrice)
  const currentETHAmount = useSelector(state => state.crypto.ethAmount)
  const currentCAKEAmount = useSelector(state => state.crypto.cakeAmount)
  const currentBTCAmount = useSelector(state => state.crypto.btcAmount)
  const currentPOLYAmount = useSelector(state => state.crypto.polyAmount)
  const [totalFundUnits, setTotalFundUnits] = useState(null) 
  
  useEffect(() => {

    if(user.role === "freeUser"){
      const query = "userDash"
      history(`/subscriptionpage/${query}`)
     } else if(user.role === "miner"){
      history('/minerdash')
     }
  }, [user])
  

  useEffect(() => {
    dispatch(userActions.getSingleUserThunk())
    dispatch(cryptoActions.getBitcoinUSDPriceThunk())
    dispatch(cryptoActions.getEthereumUSDPriceThunk())
    dispatch(cryptoActions.getBNBUSDPriceThunk())
    dispatch(cryptoActions.getCAKEUSDPriceThunk())
    dispatch(cryptoActions.getPOLYUSDPriceThunk())
    dispatch(cryptoActions.getCurrentCryptoAmountThunk())
    dispatch(cryptoActions.getOHLCVBNBThunk());
    dispatch(cryptoActions.getOHLCVBTCThunk());
    dispatch(cryptoActions.getOHLCVETHThunk());
    dispatch(cryptoActions.getOHLCVCAKEThunk());
    dispatch(cryptoActions.getOHLCVMATICThunk());
  }, [])
//add getDailyfunddata into return, make it async and await for values
  useEffect( () => {
    return axiosWithAuth()
    .get('/fund/currentfundworth')
    .then((res) => {
      setCurrentFundValue(res.data.data.currentCompoundFundWorth)
      setTotalFundUnits(res.data.data.totalUnits)
      setDailyFundValue(res.data.data.dailyCompoundFundWorth)
      
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])
  
  
  const ethPriceAndAmount = (currentETHAmount * ethereumUSDValue)
  const cakePriceAndAmount = (currentCAKEAmount * cakeUSDValue)
  const btcPriceAndAmount = (currentBTCAmount * bitcoinUSDValue)
  const polyPriceAndAmount = (currentPOLYAmount * polyUSDValue)
  const cryptoFundWorth = ethPriceAndAmount  + cakePriceAndAmount + btcPriceAndAmount + polyPriceAndAmount
  const unitPercentage = user.units / totalFundUnits
  const userInvestorAmount = cryptoFundWorth * unitPercentage
  const unitValue = cryptoFundWorth / totalFundUnits
  const initialUnitWorth = user.initBalance * 0.95
  const growth = (userInvestorAmount - initialUnitWorth) / initialUnitWorth
  const userGrowth = growth * 100
  const growthInDollars = userInvestorAmount - user.initBalance
  const fundPercent = unitPercentage * 100
  const investorLineRangeMax = userInvestorAmount * 5

  



  const dateFormatting = (replace, isString) => {
    //if data coming in is a string, turn into number
      if(isString){
        return replace.replaceAll('-', '');
      }else{
        //if data is not a string, turn back into formatted date string.
      
        let replaceToString = replace.replaceAll('-', '');
        let month = replaceToString.substring(0, 2);
        let day = replaceToString.substring(2, 4);
        let year =  replaceToString.substring(4, 6);
        let startDate = month + "/" + day + "/20" + year + ", 12:00:00 AM"
        
        
        let test = new Date(startDate)
      
        let formattedDate ={
          testDate: Date.parse(test),
          actualDate:month + "-" + day + "-" + year

        }
        
        return formattedDate
      }
  }
  const am5DateFormatting = (replace, isString) => {
    if(isString){
      return replace.replaceAll('-', '');
    }else{
      
      let replaceToString = replace.replaceAll('-', '');
      let month = replaceToString.substring(0, 2);
      let day = replaceToString.substring(2, 4);
      let year =  replaceToString.substring(4, 6);
      let formattedDate = "20" + year + "-" + month + "-" + day
     
      return formattedDate
    }
  }

  let dailyIndividualData = []
  let dailyIndividualDates = []
  let userData = []
  let userBalanceArray = []
  let tempArray = []
  if(dailyFundValue){
    //checking if dailyfundvalue has loaded, if so then grab user creation date
    //and turn it into a number
   
    let am5DailyFundValue = dailyFundValue.forEach(day => {
        
      if(day.date === "08-19-21"){
        tempArray.push(
        {
          date:'08-19-21',
          balance:267881.53
        },
          {
            date:'08-20-21',
            balance:270691.51
        },
        {
          date:'08-21-21',
          balance:282336.73
      },
      {
        date:'08-22-21',
        balance:293155.28
    },
    {
      date:'08-23-21',
      balance:297418.13
  },
  {
    date:'08-24-21',
    balance:301627.54
  },)
  
      } else {
        tempArray.push(day)
      }
    })
    
   
   const userCreationDate = user.created_at.replaceAll('-','')
   
   const created_at = userCreationDate.substring(0, 2) + "/" + userCreationDate.substring(2, 4)  + "/20" + userCreationDate.substring(4, 6) + ", 12:00:00 AM"
   const dateCreatedAt = new Date(created_at)
   

    if(userCreationDate){
      //if usercreationdate is truthy, add the original date as first in the array
      //and add the value of 0 as first in the array.  Could update to show as initial investment.
      dailyIndividualDates.push(user.created_at)
      userBalanceArray.push(user.initBalance)
    }
    //going over the total data in the fund and formatting the date
      dailyIndividualData = tempArray.map(bal => {
        let formatTest = dateFormatting(bal.date, false)
       
        if(+formatTest.testDate >  Date.parse(dateCreatedAt) || formatTest.testDate ===  Date.parse(dateCreatedAt)){
          
        
          //if the date is after the creation of the user, then return it to string form and put inside date array
          // let numberToString = dateFormatting(formatTest, false)
          let am5NumberToString = am5DateFormatting(formatTest.actualDate, false)
          
          dailyIndividualDates.push(am5NumberToString)
          //capture the balance of the fund during the dates after user creation
          let userBalance = bal.balance * unitPercentage
          
          let twoDigitBalance = +userBalance.toFixed(2)
          userData.push({
            'date':am5NumberToString,
            'balance':twoDigitBalance
          })
          
        }
    })
    
  }

  let sixMonthGraphData = []
  let thirtyDayGraphData = []
  if(userData){
    
    thirtyDayGraphData = userData.slice(-30)
    
    sixMonthGraphData = userData.slice(-182)
    // thirtyDayGraphData.map((bal) => {
    //   thirtyDayGraphDates.push(bal.date)
    //   thirtyDayGraphBalances.push(bal.balance)
    // })
  }

  const lineGraphStates = new HashMap()
  
  lineGraphStates.set('all_time_data', JSON.stringify(userData))
  lineGraphStates.set('30_day_data', JSON.stringify(thirtyDayGraphData))
  lineGraphStates.set('six_month_data', JSON.stringify(sixMonthGraphData))
  
  const lineGraphStateSetter = (string) => {
    console.log('graphstatekey', string)
    setLineGraphStateKey(string)
  }

  




  const userCardData = [
    // {
    //   id:'initialInvestment',
    //   title:'Initial Units Value',
    //   info:`$${initialUnitWorth.toLocaleString(undefined, { maximumFractionDigits: 2 })}`,
    //   tooltipText:'What your initial investment was worth',
    //   getSVG: () => {
    //     return ( <InitialInvestmentSVG/>
    //       )
    //     }
    // },
    {
      id:'currentUnitWorth',
      title:'Current Unit Value',
      info:`$${unitValue.toLocaleString(undefined, { maximumFractionDigits: 2 })}`,
      tooltipText:'Live value of a singular unit',
      getSVG: () => {
        return  <UserTotalUnitsSVG/>
      }
    },
    {
      id:'unitsOwned',
      title:'Total Units Owned',
      info:user.units,
      tooltipText:"Represents how many units you currently own",
      getSVG: () => {
        return  <UserTotalUnitsSVG/>
      }
    },
    // {
    //   id:'fundPercentage',
    //   title:'Fund Percentage',
    //   info:`${fundPercent.toFixed(2)}%`,
    //   tooltipText:'Current percentage of the fund you own',
    //   getSVG: () => {
    //     return  <FundPercentageSVG/>
    //   }
    // },
  ]  


  return (
    <div className=" h-screen flex overflow-visible">
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
      <div className="relative flex flex-col flex-1  ">
      <Header text="" userFirstName={user.firstName} userLastName={user.lastName}/>
     <div className="overflow-auto">
       <div className="flex flex-col min-h-screen overflow-auto justify-center items-center bg-dashboardBackGroundGray">
        
         
         <div className="flex flex-row xxxs:flex-col xxs:flex-col xxs:items-center xs:flex-col xs:items-center sm:items-center sm:flex-col md:flex-col md:items-center lg:flex-row justify-center w-full mt-8">
        {
          userCardData.map((card) => {
           return( 
             <UserInfoCard title={card.title} info={card.info} getSVG={card.getSVG} tooltipText={card.tooltipText}/>
            ) 
             
          })
        }
            </div>
            <LineGraphWrapper data={lineGraphStates.get(lineGraphStateKey)} graphStateSetter={lineGraphStateSetter}  currentInvestorAmount={userInvestorAmount} growth={userGrowth} dataScope={"Account"}/>
            
       </div>
     </div>
    </div>
      </div>
  )
}


