import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import UserTotalUnitsSVG from "../../assets/SVGs/UserTotalUnitsSVG";
import SideBar from "../../partials/navbar/SideBar";
import Header from "../../partials/navbar/Header";
import PaymentCardWrapper from "../../partials/payment/PaymentCardWrapper";
import Cookies from 'js-cookie'
import CreditCardForm from "../../partials/forms/CreditCardForm";
import BillingInfoForm from "../../partials/forms/BillingInfoForm";
export default function SubscriptionPaymentPage() {
  const user = useSelector((state) => state.user.user);
  const [showSideBar, setShowSideBar] = useState(false);
  const [chris, showChris] = useState(true)
  const [queryState, setQueryState] = useState(false)
  const history = useNavigate()
  const id = Cookies.get('subscriptionID')
  const { query } = useParams()
  const [queryMessage, setQueryMessage] = useState('')

  setTimeout(() => {
    if(query === "userDash"){
      setQueryMessage("Select a payment plan to view your personalized dashboard")
      setQueryState(true)
    } else if(query === "fundDash"){
      setQueryMessage("Start a payment plan to view the fund dashboard")
      setQueryState(true)
    }
  }, 300)

  if(id){
    Cookies.remove('subscriptionID')
    history(`/subscription/${id}`)
  }

  
  const ids = ["15", "50", "100", "custom"]
  return (
    <div className=" h-screen flex overflow-visible ">
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div className="relative flex flex-col flex-1  ">
        <Header text="" userFirstName={user.firstName} userLastName={user.lastName} />
        <main>
          <div className="flex flex-col min-h-screen h-screen overflow-y-scroll  items-center bg-dashboardBackGroundGray">
           {
             chris ? 
             <div>
               <i className=" mt-20 text-indigo-500 fa-solid fa-person-digging text-9xl"></i>
               <h2 className="text-4xl mb-8 mt-6 text-indigo-500 font-semibold"> This page is still being developed.</h2>
               <h2 className="text-4xl text-indigo-500 font-semibold"> Check back soon to see whats new!</h2>

               <button className="bg-dashboardBackGroundGray border-b-2 mt-32 w-20 h-20" onClick={()=>{showChris(false)}}></button>
             </div>
             :
             <>
                <h1 className="text-4xl text-indigo-500 font-semibold">
                  Step 1: Select a payment plan
                </h1>
                {
                  queryState ?
                <div class="p-4 mt-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
  <span class="font-medium">Subscription required:</span> {queryMessage}
</div>
                :
                <></>
                }

                <PaymentCardWrapper>
                <Link to={`/subscription/${ids[0]}`}> 
                  <div className="flex flex-row">
                    <div className="flex flex-col justify-center items-center w-1/3 h-32 ">
                      <UserTotalUnitsSVG />
                      <p className="font-bold text-indigo-500">15$</p>
                    </div>
                    <div className="flex flex-col w-2/3 bg-indigo-500 h-32 rounded-2xl text-center">
                      <p className="text-dashboardBackGroundGray mt-4 mx-2 mb-8">
                        This is sample text to show what we can display toward a
                        potential investor. This can highlight what each price
                        package does, how much it costs, and the benefit for the
                        user.
                      </p>
                    </div>
                  </div>
                </Link>
                </PaymentCardWrapper>
                
                <PaymentCardWrapper >
                  <Link to={`/subscription/${ids[1]}`}>
                  <div className="flex flex-row">
                    <div className="flex flex-col justify-center items-center w-1/3 h-32 ">
                      <UserTotalUnitsSVG />
                      <p className="font-bold text-indigo-500">50$</p>
                    </div>
                    <div className="flex flex-col w-2/3 bg-indigo-500 h-32 rounded-2xl text-center">
                      <p className="text-dashboardBackGroundGray mt-4 mx-2">
                        This is sample text to show what we can display toward a
                        potential investor. This can highlight what each price
                        package does, how much it costs, and the benefit for the
                        user.
                      </p>
                    </div>
                  </div>
                  </Link>
                </PaymentCardWrapper>
                
                <PaymentCardWrapper >
                <Link to={`/subscription/${ids[2]}`}>
                  <div className="flex flex-row">
                    <div className="flex flex-col justify-center items-center w-1/3 h-32 ">
                      <UserTotalUnitsSVG />
                      <p className="font-bold text-indigo-500">100$</p>
                    </div>
                    <div className="flex flex-col w-2/3 bg-indigo-500 h-32 rounded-2xl text-center">
                      <p className="text-dashboardBackGroundGray mt-4 mx-2">
                        This is sample text to show what we can display toward a
                        potential investor. This can highlight what each price
                        package does, how much it costs, and the benefit for the
                        user.
                      </p>
                    </div>
                  </div>
                </Link>
                </PaymentCardWrapper>
               
           
              </>
              }
           
          </div>
        </main>
      </div>
    </div>
  );
}
