import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { cryptoActions } from '../../store/ducks/cryptoDucks'
import { userActions } from '../../store/ducks/userDuck'
import { axiosWithAuth } from '../../utils/axiosWithAuth'
import SideBar from '../../partials/navbar/SideBar'
import Header from '../../partials/navbar/Header'
import LineGraphWrapper from '../../partials/dashboard/LineGraphWrapper'
import FundLineGraphWrapper from '../../partials/dashboard/FundLineGraphWrapper'
import FundInfoCard from '../../partials/dashboard/FundInfoCard'
import PieGraphWrapper from '../../partials/dashboard/PieGraphWrapper'
var HashMap = require('hashmap')




export default function FundDash() {
  const dispatch = useDispatch()
  const [showSideBar, setShowSideBar] = useState(false)
  const user = useSelector(state => state.user.user)
  const history = useNavigate()
  
  
  const bitcoinUSDValue = useSelector((state) => state.crypto.btcUSDPrice);
  const ethereumUSDValue = useSelector((state) => state.crypto.ethUSDPrice);
  const cakeUSDValue = useSelector((state) => state.crypto.cakeUSDPrice);
  const bnbUSDValue = useSelector((state) => state.crypto.bnbUSDPrice);
  const polyUSDValue = useSelector((state) => state.crypto.polyUSDPrice);
  const currentETHAmount = useSelector((state) => state.crypto.ethAmount);
  const currentCAKEAmount = useSelector((state) => state.crypto.cakeAmount);
  const currentBTCAmount = useSelector((state) => state.crypto.btcAmount);
  const currentPOLYAmount = useSelector((state) => state.crypto.polyAmount);
  // const OHLCVBTC = useSelector((state) => state.crypto.btcOHLCV)
  // const OHLCVBNB = useSelector((state) => state.crypto.bnbOHLCV)
  // const OHLCVETH = useSelector((state) => state.crypto.ethOHLCV)
  // const OHLCVCAKE = useSelector((state) => state.crypto.cakeOHLCV)
  // const OHLCVMATIC = useSelector((state) => state.crypto.maticOHLCV)
  const [currentFundValue, setCurrentFundValue] = useState(null);
  const [currentInitFundValue, setCurrentInitFundValue] = useState(null);
  const [growthDollarToPercent, setGrowthDollarToPercent] = useState(true);
  const [lineGraphStateKey, setLineGraphStateKey] = useState("all_time_data");
  const [pieGraphStateKey, setPieGraphStateKey] = useState("dollar_allotment");
  const [totalFundUnits, setTotalFundUnits] = useState(null);
  const [dailyFundValue, setDailyFundValue] = useState(null);
  const [lineMenuToggle, setLineMenuToggle] = useState(false);
  const [pieMenuToggle, setPieMenuToggle] = useState(false);



  useEffect(() => {
    dispatch(cryptoActions.getBitcoinUSDPriceThunk());
    dispatch(cryptoActions.getEthereumUSDPriceThunk());
    dispatch(cryptoActions.getBNBUSDPriceThunk());
    dispatch(cryptoActions.getCAKEUSDPriceThunk());
    dispatch(cryptoActions.getPOLYUSDPriceThunk());
    dispatch(cryptoActions.getCurrentCryptoAmountThunk());
    // dispatch(cryptoActions.getOHLCVBNBThunk());
    // dispatch(cryptoActions.getOHLCVBTCThunk());
    // dispatch(cryptoActions.getOHLCVETHThunk());
    // dispatch(cryptoActions.getOHLCVCAKEThunk());
    // dispatch(cryptoActions.getOHLCVMATICThunk());
  }, []);

  useEffect(() => {
    return axiosWithAuth()
      .get("/fund/currentfundworth")
      .then((res) => {
        setCurrentFundValue(res.data.data.currentCompoundFundWorth);
        setTotalFundUnits(res.data.data.totalUnits);
        
        setCurrentInitFundValue(res.data.data.currentInitialFundWorth);
        
        setDailyFundValue(res.data.data.dailyCompoundFundWorth);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ethPriceAndAmount = currentETHAmount * ethereumUSDValue;
  const cakePriceAndAmount = currentCAKEAmount * cakeUSDValue;
  const btcPriceAndAmount = currentBTCAmount * bitcoinUSDValue;
  const polyPriceAndAmount = currentPOLYAmount * polyUSDValue;
  const cryptoFundWorth =
    ethPriceAndAmount +
    cakePriceAndAmount +
    btcPriceAndAmount +
    polyPriceAndAmount;
  const cakeFundAllotment = (cakePriceAndAmount / cryptoFundWorth) * 100;
  const ethFundAllotment = (ethPriceAndAmount / cryptoFundWorth) * 100;
  const btcFundAllotment = (btcPriceAndAmount / cryptoFundWorth) * 100;
  const polyFundAllotment = (polyPriceAndAmount / cryptoFundWorth) * 100;
  const growth =
    (cryptoFundWorth - currentInitFundValue) / currentInitFundValue;
  const dollarGrowth = cryptoFundWorth - currentInitFundValue;
  const fundGrowth = growth * 100;
  
  let unitPrice = cryptoFundWorth / totalFundUnits;

  const am5DateFormatting = (replace, isString) => {
    if(isString){
      return replace.replaceAll('-', '');
    }else{
      
      let replaceToString = replace.replaceAll('-', '');
      let month = replaceToString.substring(0, 2);
      let day = replaceToString.substring(2, 4);
      let year =  replaceToString.substring(4, 6);
      let formattedDate = "20" + year + "-" + month + "-" + day
     
      return formattedDate
    }
  }

  useEffect(() => {
    if(user.role === "freeUser"){
      const query = "fundDash"
      history(`/subscriptionpage/${query}`)
     }
  }, [user])

  let thirtyDayGraphData = [];
  let sixMonthGraphData = []
  let fundData = []
  let tempArray = []
  if (dailyFundValue) {
    thirtyDayGraphData = dailyFundValue.slice(-30)
    sixMonthGraphData = dailyFundValue.slice(-182)
    let am5DailyFundValue = dailyFundValue.forEach(day => {
      if(day.date === "08-19-21"){
        tempArray.push(
          {
            date:'08-19-21',
            balance:267881.53
          },
            {
              date:'08-20-21',
              balance:270691.51
          },
          {
            date:'08-21-21',
            balance:282336.73
        },
        {
          date:'08-22-21',
          balance:293155.28
      },
      {
        date:'08-23-21',
        balance:297418.13
    },
    {
      date:'08-24-21',
      balance:301627.54
    },)
    
        } else {
          tempArray.push(day)
        }
      
    })
  }

    if(tempArray !== []){
      let dailyFunDatas = tempArray.map(bal => {
        let am5NumberToString = am5DateFormatting(bal.date, false)
        let twoDigitBalance = +bal.balance
        fundData.push({
          "date":am5NumberToString,
          'balance':twoDigitBalance
        })
      })
    }

    if(fundData !== []){
      thirtyDayGraphData = fundData.slice(-30)
    sixMonthGraphData = fundData.slice(-182)
    }

  const lineGraphStates = new HashMap();
  lineGraphStates.set("all_time_data", JSON.stringify(fundData));
  lineGraphStates.set("thirty_day_data", JSON.stringify(thirtyDayGraphData));
  lineGraphStates.set('six_month_data', JSON.stringify(sixMonthGraphData))

  const lineGraphStateSetter = (string) => {
    setLineGraphStateKey(string)
  }

 const lineMenuToggler = () => {
    setLineMenuToggle(!lineMenuToggle);
  };

  const pieMenuToggler = () => {
    setPieMenuToggle(!pieMenuToggle);
  };
  
  const pieDollarData = [
    {
      "coinName": "Ethereum",
      "coinAmount": +ethPriceAndAmount.toFixed(2),
      "symbol":"$"
    },
    {
      "coinName": "PancakeSwap",
      "coinAmount": +cakePriceAndAmount.toFixed(2),
      "symbol":"$"
    },
    {
      "coinName": "Bitcoin",
      "coinAmount": +btcPriceAndAmount.toFixed(2),
      "symbol":"$"
    },
    {
      "coinName": "Polygon",
      "coinAmount": +polyPriceAndAmount.toFixed(2),
      "symbol":"$"
    }
  ]

  const pieCoinData = [
    {
      "coinName": "Ethereum",
      "coinAmount":+ethFundAllotment.toFixed(2),
      "symbol":"%"
    },
    {
      "coinName": "PancakeSwap",
      "coinAmount":+cakeFundAllotment.toFixed(2),
      "symbol":"%"
    },
    {
      "coinName": "Bitcoin",
      "coinAmount":+btcFundAllotment.toFixed(2),
      "symbol":"%"
    },
    {
      "coinName": "Polygon",
      "coinAmount":+polyFundAllotment.toFixed(2),
      "symbol":"%"
    },
  ]

  const pieGraphStates = new HashMap()
  pieGraphStates.set('dollar_allotment', pieDollarData)
  pieGraphStates.set('coin_allotment', pieCoinData)

  const pieGraphStateSetter = (string) => {
    setPieGraphStateKey(string)
  }





  const fundCardData = [
      {
          id:"totalFundUnits",
          title:"Total Units",
          info:+totalFundUnits,
          tooltipText:"The total amount of units presently in the fund",
        },
        {
          id:"individualUnitWorth",
          title:"Individual Unit Worth",
      info:`$${unitPrice.toLocaleString(undefined, { maximumFractionDigits: 2 })}`,
          tooltipText:"Current value of a unit",
        },
      {
        id:"fundGrowthInDollars",
        title:"Fund Growth",
        info:`$${dollarGrowth.toLocaleString(undefined, { maximumFractionDigits: 2})}`,
        tooltipText:"The growth or loss of the fund presented in dollars",
      },
      
    ]


    return (
    <div className=" min-h-screen h-full flex overflow-hidden">
        <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
        <div className="relative flex flex-col flex-1">
        <Header text="" userFirstName={user.firstName} userLastName={user.lastName}/>
        <div className="overflow-auto">
        <div className="flex flex-col justify-center items-center min-h-screen h-full bg-dashboardBackGroundGray">
            <div className="flex flex-row xxxs:flex-col xxs:flex-col xxs:items-center xs:flex-col xs:items-center sm:items-center sm:flex-col md:flex-col md:items-center lg:flex-row justify-center w-full mt-8">
                {
                    fundCardData.map((card) => {
                        return (
                            <FundInfoCard title={card.title} info={card.info} tooltipText={card.tooltipText}/>
                        )
                    })
                }
                
            </div>  
          <FundLineGraphWrapper data={lineGraphStates.get(lineGraphStateKey)} graphStateSetter={lineGraphStateSetter} currentInvestorAmount={+cryptoFundWorth} growth={fundGrowth} dataScope={"Fund"}/>
          <PieGraphWrapper data={pieGraphStates.get(pieGraphStateKey)} graphStateSetter={pieGraphStateSetter}/>
        </div>

        </div>
        </div>
    </div>
  )
}
