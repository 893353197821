import axios from 'axios';

export const baseAxiosCall = () => {
  const devURL = "http://localhost:3500"
  const prodURL = "https://ttmm-be.com"
    return axios.create({
      baseURL: prodURL,
    });
  };
  
  //  baseURL: "https://ttmm-be.com",
  //"http://localhost:3500"


//   Gabet:
// Hosting and Electricity Cost
// 2002.80$ TX
// 3516$ OH
// that total / 2

// list of payouts:
// date:amount
// fullamount 20% / 2
// 99

// Baker:
// Hosting and Electricity Cost
// 2002.80$ TX
// 3516$ OH
// that total / 2
