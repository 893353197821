import * as yup from 'yup'

export default yup.object().shape({
    cardNumber:yup
    .number('Only enter numbers')
    .required('Must enter a credit card number'),
    cardDate:yup
    .string()
    .required('Must be MM/YY format'),
    ccv:yup
    .number()
    .required('Enter the numbers from the back of your card')

})