import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import SideBar from "../../partials/navbar/SideBar";
import TempMobileView from "./TempMobileView"; 
import TempDesktopView from "./TempDesktopView";
import Header from "../../partials/navbar/Header";
import s10jproImage from "../../assets/img/s10jpro-removebg-preview.png"; // Import the image

function useWindowSize() {
    const isSSR = typeof window === 'undefined';
    const [windowSize, setWindowSize] = useState({
      width: isSSR ? 1200 : window.innerWidth,  // default to a width greater than 768px
      height: isSSR ? 800 : window.innerHeight,
    });
  
    function changeWindowSize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
  
    useEffect(() => {
      window.addEventListener('resize', changeWindowSize);
  
      return () => {
        window.removeEventListener('resize', changeWindowSize);
      };
    }, []);
  
    return windowSize;
  }


const TemporaryMiningDashboard = () => {
    const user = useSelector((state) => state.user.user);
    
const { width } = useWindowSize();
  const data = {
    Initial:{
        amountMined:"0.1259758 BTC",
        electricityAndHosting:"0.1259758 BTC",
        net:'0 BTC',
        feeForCollection:'0 BTC',
        globalPayout:"0 BTC",
        payout:"0 BTC"
    },
    June: {
      amountMined: "0.25268831 BTC",
      electricityAndHosting: "0.18042673 BTC",
      net: "0.07226158 BTC",
      feeForCollection: "0.01445232 BTC",
      globalPayout: "0.05780926",
      payout: "0.02890463 BTC",
    },
    July: {
      amountMined: "0.20845423 BTC",
      electricityAndHosting: "0.191215171 BTC",
      net: "0.017239059 BTC",
      feeForCollection: "0.003447812 BTC",
      globalPayout: "0.013791247",
      payout: "0.006895624 BTC",
    },
    August: {
      amountMined: "0.19463435 BTC",
      electricityAndHosting: "0.20234911 BTC",
      net: "-0.00771476 BTC",
      feeForCollection: "0 BTC",
      globalPayout: "-0.00771476",
      payout: "-0.00385738 BTC",
    },
    September: {
      amountMined: "0.20695863 BTC",
      electricityAndHosting: "0.17290251 BTC",
      net: "0.03405612 BTC",
      feeForCollection: "0.00681122 BTC",
      globalPayout: "0.0272449",
      payout: "0.00976507",
    },
    October: {
      amountMined: "0.20626249 BTC",
      electricityAndHosting: "0.1601102 BTC",
      net: "0.04615229 BTC",
      feeForCollection: "0.009230458 BTC",
      globalPayout: "0.036921832",
      payout: "0.018460916",
    },
    November: {
      amountMined: "0.19407132 BTC",
      electricityAndHosting: "0.12567223 BTC",
      net: "0.06839909 BTC",
      feeForCollection: "0.013679818 BTC",
      globalPayout: "0.0547192722",
      payout: "0.027359636",
    },
    December: {
      amountMined: "0.22469721 BTC",
      electricityAndHosting: "0.13160515 BTC",
      net: "0.09309206 BTC",
      feeForCollection: "0.018618412 BTC",
      globalPayout: "0.074473648",
      payout: "0.037236824",
    },
    January: {
      amountMined: "0.16944135 BTC",
      electricityAndHosting: "0.11987412 BTC",
      net: "0.04956723 BTC",
      feeForCollection: "0.00991345 BTC",
      globalPayout: "0.03965378",
      payout: "0.02478362",
    } 
   
    // add more months as needed
  };

  const labels = {
    amountMined: "Amount Mined",
    electricityAndHosting: "Electricity and Hosting",
    net: "Net",
    feeForCollection: "Fee",
    globalPayout: "Payout",
    payout: "Personal Payout",
  };

  const deviceList = [
    "57x10x242x199",
    "oh2blld",
    "oh2bqbz",
    "oh2bsli",
    "57x10x242x193",
    "oh2cluv",
    "oh2cvgk",
    "oh2debl",
    "oh2dgiw",
    "57x10x242x198",
    "57x10x242x188",
    "oh2dofl",
    "oh2dogt",
    "oh2ebes",
    "57x10x242x189",
    "oh2ectl",
    "oh2ekef",
    "57x10x242x197",
    "oh2ewea",
    "oh2ffno",
    "57x10x242x195",
    "57x10x242x196",
    "oh2ffyh",
    "oh2ffgo",
    "oh2fjyg",
    "57x10x242x191",
    "oh2fkuh",
    "oh2fxyt",
    
    "oh2gayf",
    "57x10x242x177",
    "57x10x242x186",
    
   
    
    
    
    
    
    
    
  ];
  const halfwayThrough = Math.ceil(deviceList.length / 2);
  const deviceListFirstHalf = deviceList.slice(0, halfwayThrough);
  const deviceListSecondHalf = deviceList.slice(halfwayThrough);
  return (
    <div className="h-screen flex overflow-auto">
      <SideBar />
      <div className="relative flex flex-col flex-1 pb-4">
        <Header
          userFirstName={user.firstName}
          userLastName={user.lastName}
          text="Miner Information"
        />

        <div className="mt-6 hidden md:flex flex-row items-start justify-center">
          <div className="flex flex-col items-center w-full md:w-1/2 md:mr-[-1]">
            <img src={s10jproImage} alt="s10jpro" className="mx-auto md:mx-0 w-full md:w-auto border-2 border-gray-300" />
            <p className="mt-1 text-xs text-center md:text-left">S19J-Pro</p>
          </div>

          <div className="mt-4 md:mt-0 md:w-1/2">
            <h2 className="mb-4 text-center text-2xl sm:text-3xl lg:text-4xl">
              Miner Names
            </h2>
            <div className="grid grid-cols-2">
              {deviceListFirstHalf.map((device, index) => (
                <div key={device} className="w-full">
                  <li>{device}</li>
                </div>
              ))}
              {deviceListSecondHalf.map((device, index) => (
                <div key={device} className="w-full">
                  <li>{device}</li>
                </div>
              ))}
            </div>
          </div>
        </div>

        <h1 className="mt-4 text-center text-2xl sm:text-3xl lg:text-4xl">
          Payment Overview
        </h1>
        
        {width <= 768 ? <TempMobileView data={data} labels={labels} /> : <TempDesktopView data={data} labels={labels} />}
      </div>
    </div>
  );
};

export default TemporaryMiningDashboard;
