import React from "react";
import EditMenu from "./EditMenu";
import CoinAllotmentPieGraph from "./CoinAllotmentPieGraph";
export default function PieGraphWrapper({graphStateSetter, data}) {


  return (
    <div className="xxxs:hidden xxs:flex flex-col w-3/6 overflow-visible  bg-dashboardBackGroundGray rounded-sm mt-8">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-end mb-2">
          {/* <EditMenu className="mt-6 relative inline-flex">
            <li>
              <button
                className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                onClick={(string) => graphStateSetter("dollar_allotment")}
              >
                Dollar Distribution
              </button>
            </li>
            <li>
              <button
                className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                onClick={(string) => graphStateSetter("coin_allotment")}
              >
                Coin Distribution
              </button>
            </li>
          </EditMenu> */}
        </header>
        <h2 className="text-lg font-bold text-gray-800 mb-2">Fund Allotment</h2>
      </div>
      <div className="grow mb-32 h-128 overflow-visible">
          <CoinAllotmentPieGraph data={data}/>
      </div>
    </div>
  );
}
