import React from "react";

const TempDesktopView = ({ data, labels }) => {
    return (
        <div className="overflow-x-auto mt-4 mx-0 w-full">
          <div className="grid grid-cols-7 gap-4 my-2 mb-0 border rounded-lg p-4 border-b rounded-none p-2 odd:bg-gray-100 w-full">
            <div className="text-indigo-500">
              <strong>Month</strong>
            </div>
            {Object.keys(data['Initial']).map((key, index) => (
              <div key={index} className="text-indigo-500">
                <strong>{labels[key]}</strong>
              </div>
            ))}
          </div>
          {Object.entries(data).map(([month, values]) => (
            <div className="grid grid-cols-7 gap-4 my-2 mb-0 border rounded-lg p-4 border-b rounded-none p-2 odd:bg-gray-100 w-full">
              <div className="text-black">
                <strong>{month}</strong>
              </div>
              {Object.values(values).map((value, index) => (
                <div key={index}>{value}</div>
              ))}
            </div>
          ))}
        </div>
      );
              }

export default TempDesktopView;