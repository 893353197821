import React from 'react'
import HeaderDropdown from './HeaderDropdown'



export default function Header({userFirstName, userLastName, text}) {

  return (
    <div className="sticky top-0 bg-white border-b border-gray-200 z-30">
        <div className="flex items-center justify-between h-16 -mb-px">

<hr className="w-px h-6 bg-gray-200 mx-3" />
<div className="text-4xl ml-24 font-semibold tracking-normal font-Roboto">
  {text}
</div>
 <HeaderDropdown userFirstName={userFirstName} userLastName={userLastName}/>
        </div>
    </div>
  )
}
