import React, {useState, useEffect} from 'react'
import Cookies from 'js-cookie';
import LoginSVG from '../../assets/SVGs/LoginSVG'
import LGLoginSVG from '../../assets/SVGs/LGLoginSVG';
import { useNavigate, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import { userActions } from '../../store/ducks/userDuck'
import { cryptoActions } from '../../store/ducks/cryptoDucks'

const initialState = {
    email:'',
    password:''
}

export default function Login() {
const dispatch = useDispatch()
const history = useNavigate()
const [loginError, setLoginError] = useState(null)
const [form, setForm] = useState(initialState)
const isLoggedIn = useSelector(state => state.user.role)






const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault()
    dispatch(userActions.loginThunk(form))
    setTimeout(() => {
        if(Cookies.get('auth_token')){
            console.log('came down this path isTrue cookie')
            setLoginError(false)
            console.log('login role', isLoggedIn)
            history('/userdash')

        } else {

            setLoginError(true)
        }
    }, 400)
  }

  useEffect( () => {
      const userToken = Cookies.get('auth_token')
      const rft = Cookies.get('rft')
      console.log(rft)
      if(rft){
          dispatch(userActions.loginWithTokenThunk(rft))
          history('/userdash')
      }
  }, [])

//   useEffect( () => {
//     if(isLoggedIn){
//       setTimeout(() => {
//         console.log('came thru this path isLoggedIn')
//         dispatch(userActions.getSingleUserThunk())
//         history('/userdash')
//       }, 500)
//     }
//   }, [isLoggedIn])


  useEffect(() => {
    dispatch(userActions.resetUserStateThunk())
    dispatch(cryptoActions.resetCryptoStateThunk())
  }, [])

  return (
    <div class="bg-white">
      <div class="lg:flex">
            <div class="lg:w-1/2 xl:max-w-screen-sm">
                <div class="py-12 bg-white lg:bg-white flex justify-center lg:justify-start lg:px-12">
                    <div class="cursor-pointer flex items-center">
                    <i class="fad fa-moon text-indigo-800 text-2xl w-10"></i>
                        <div class="text-2xl text-indigo-800 tracking-wide ml-2 font-semibold">Lunam</div>
                    </div>
                </div>
      {
      loginError ?
      <div className="flex text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500 w-full justify-center m-auto mb-10">
        <span className="text-xl inline-block mr-5 align-middle">
          <i className="fas fa-bell" />
        </span>
        <span className="inline-block align-middle mr-8">
          <b className="capitalize">error:</b> Invalid Credentials
        </span>
        <button onClick={() => setLoginError(false)} className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none">
          <span>X</span>
        </button>
      </div>
      :
      <>
      </>
      }
                <div class="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl bg-white">
                    <h2 class="text-center text-4xl text-indigo-800 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold">Log in</h2>
                    <div class="mt-12">
                        <form onSubmit={(e) => handleLogin(e)}>
                            <div>
                                <div class="text-sm font-bold text-gray-300 tracking-wide">Email Address</div>
                                <input class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500" name="email" value={form.email} onChange={(e) => handleChange(e)} placeholder="mike@gmail.com"/>
                            </div>
                            <div class="mt-8">
                                <div class="flex justify-between items-center">
                                    <div class="text-sm font-bold text-gray-300 tracking-wide">
                                        Password
                                    </div>
                                    <div>
                                        <Link className="text-xs font-display font-semibold text-indigo-800 hover:text-indigo-800
                                        cursor-pointer" to="/emailpasswordreset">
                                            Forgot Password?
                                        </Link>
                                    </div>
                                </div>
                                <input class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500" type="password" placeholder="Enter your password" name="password" value={form.password} onChange={(e) => handleChange(e)}/>
                            </div>
                            <div class="mt-10">
                                <button class="bg-indigo-800 text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg" type="submit">
                                    Log In
                                </button>
                            </div>
                        </form>
                        <div class="mt-12 text-sm font-display font-semibold text-gray-700 text-center">
                            Don't have an account? <Link class="cursor-pointer text-indigo-700 hover:text-indigo-800" to="/registration">Sign up</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden lg:flex items-center  bg-white flex-1 h-screen">
                <div class="max-w-xs transform duration-200 hover:scale-110 cursor-pointer">
                    <div class="w-5/6 ml-10 mb-20 lg:hidden xl:hidden 2xl:w-1/2 2xl:inline" onClick={(e) => handleLogin(e)}>
                    <LoginSVG/>
                    </div>
                    <div className="w-5/6 ml-10 mb-32 md:hidden lg:inline xl:inline 2xl:hidden">
                    <LGLoginSVG/>
                    </div>
                </div>
            </div>
        </div>
  </div>
  )}
