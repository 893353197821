import React, {useState} from 'react'
import { useDispatch } from 'react-redux'

import { userActions } from '../../store/ducks/userDuck'
import { cryptoActions } from '../../store/ducks/cryptoDucks'
import UserAvatar from '../../assets/img/user-avatar-32.png'
import TransitionTemplate from '../../utils/transition/TransitionTemplate'
import { useNavigate } from 'react-router-dom'


export default function HeaderDropdown({userFirstName, userLastName}) {
  const [dropDownOpen, setDropdownOpen] = useState(false)
  const history = useNavigate()
  const dispatch = useDispatch()



  const signOut = (e) => {
            e.preventDefault();
            dispatch(userActions.resetUserStateThunk())
            dispatch(cryptoActions.resetCryptoStateThunk())
            dispatch(userActions.signoutThunk())
                history('/login')
                window.location.reload()
  }
  
    return (
    <div className="relative inline-flex">
        <button
        className="inline-flex justify-center group pr-8"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropDownOpen)}
        aria-expanded={dropDownOpen}
        >
        <img className="w-8 h-8 rounded-full" src={UserAvatar} width="32" height="32" alt="User Avatar"/>
        <div className="flex items-center truncate">
        <span className="truncate ml-2 mt-1 text-sm font-medium group-hover:text-sideBarTextSlate">{userFirstName}</span>
        <svg className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400" viewBox="0 0 12 12">
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
        </button>

        <TransitionTemplate
        className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropDownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        >
            <div
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
            >
                <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
                 <div className="font-medium text-gray-800">{userFirstName} {userLastName}</div>
                </div>
                <ul>
                    <li>
                        <button className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center pl-14" onClick={(e) => signOut(e)}>Sign Out</button>
                    </li>
                </ul>
            </div>
        </TransitionTemplate>

        </div>
  )
}
