import React, {useEffect, useState, useMemo, useReft} from 'react'
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
export default function IndividualMinerGraph({data, name, color}) {
    console.log('individual minergraph data', data)
    const gigahash = 1000000000
    const terahash = 1000000000000
    


    let newArray = []
    data.forEach(hash => {
        let testDate = new Date(hash.date)
        newArray.push({"date":testDate,'hashrate':(hash.hashrate / terahash) / 6})
    })
    useEffect(() => {
        let graphStroke 
    
    if(color === "litecoin"){
        graphStroke = "#325A98"
    }else if(color === "bitcoin"){
        graphStroke = "#F7931A"
        console.log('here')
    }
        
        const root = am5.Root.new(`litecoinMiner${name}`)
    
    
    root.dateFormatter.setAll({
        dateFormat:"yyyy",
        dateFields:["ValueX"]
    })
    
    root.setThemes([
        am5themes_Animated.new(root)
    ])
    
    
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
        focusable:true,
       
    }))
    let easing = am5.ease.linear 

    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
        maxDeviation:0.1,
        groupData:false,
        baseInterval:{
            timeUnit:'minute',
            count:10
        },
       
        renderer:am5xy.AxisRendererX.new(root, {

        }),
        tooltip:am5.Tooltip.new(root, {})
    }))

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation:0.2,
        renderer:am5xy.AxisRendererY.new(root, {})

    }))

    let series = chart.series.push(am5xy.LineSeries.new(root, {
        minBulletDistance:10,
        connect:false,
        xAxis:xAxis,
        yAxis:yAxis,
        valueYField:'hashrate',
        valueXField:"date",
        tooltip:am5.Tooltip.new(root, {
            pointerOrientation:'horizontal',
            labelText:"{valueY} TH/s"
        })
    }))
    series.set("fill", am5.color(graphStroke))
    series.set('stroke', am5.color(graphStroke))
    series.fills.template.setAll({
        fillOpacity:0.2,
        visible:true
    })

    series.strokes.template.setAll({
        strokeWidth:2
    })

    series.data.processor = am5.DataProcessor.new(root, {
        dateFormat:'YYYY-MM-DDTHH:mm:ss',
        dateFields:["date"]
    })
    
    series.data.setAll(newArray)


    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        xAxis:xAxis,
        behavior:'none'
    }))

    cursor.lineY.set("visible", false)

    



   
    return () => {
        root.dispose()
    }
    }, [data])
  return (
    <div id={`litecoinMiner${name}`} className="h-full w-full"></div>
  )
}
